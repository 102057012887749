import React from 'react';
import { useLazyQuery as useLazyQueryOriginal } from '@apollo/react-hooks';

// This hook is just a wrapper to apollos useLazyQuery hook,
// which return a promise
export function useLazyQueryPromise(query, options) {
  const [execute, result] = useLazyQueryOriginal(query, options);

  const resolveRef = React.useRef(null);

  React.useEffect(() => {
    if (result.called && !result.loading && resolveRef.current) {
      resolveRef.current(result);
      resolveRef.current = undefined;
    }
  }, [result]);

  const queryLazily = React.useCallback(
    (variables, context) => {
      execute({ variables, context });
      return new Promise(resolve => {
        resolveRef.current = resolve;
      });
    },
    [execute]
  );

  return [queryLazily, result];
}
