export const locationTypes = {
  private: 'Privat (z.B. zuhause o. bei Freunden)',
  carBreakdown: 'Auto Panne',
  campsite: 'Campingplatz',
  landVergnuegen: 'Landvergnügen',
  francePassion: 'France Passion',
  poi: 'Sehenswürdigkeit',
  privatParkingSpace: 'Privater Parkplatz',
  publicParkingSpace: 'Öffentlicher Parklpatz',
  misc: 'Sonstiges',
  pitch: 'Wohnmobil Stellplatz',
  wildCamping: 'Wildcamping'
};

export const services = [
  'Barrierefrei',
  'Dusche',
  'Grillen erlaubt',
  'Internet',
  'Müllentsorgung',
  'Strom',
  'Tiere Erlaubt',
  'Tisch & Sitzmöglichkeiten',
  'Touristen Information',
  'WC',
  'WC Entsorgung',
  'Wasser'
];
