// Vendor imports
import React from 'react';
import classNames from 'classnames';

// Custom imports
import Icon from './Icon';

// Component
const Divider = ({ rating, additionalClassNames }) => {
  // Setup css classes
  const cssClasses = classNames('rating', additionalClassNames);

  return (
    <div className={cssClasses}>
      <Icon
        icon={rating > 0 ? 'star-filled' : 'star'}
        additionalClassNames="rating__star"
      />
      <Icon
        icon={rating > 1 ? 'star-filled' : 'star'}
        additionalClassNames="rating__star"
      />
      <Icon
        icon={rating > 2 ? 'star-filled' : 'star'}
        additionalClassNames="rating__star"
      />
      <Icon
        icon={rating > 3 ? 'star-filled' : 'star'}
        additionalClassNames="rating__star"
      />
      <Icon
        icon={rating > 4 ? 'star-filled' : 'star'}
        additionalClassNames="rating__star"
      />
    </div>
  );
};

export default Divider;
