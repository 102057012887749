// Vendor imports
import React from 'react';
import classNames from 'classnames';

// Custom imports
import { regexPatterns } from '../../../constants/miscellaneous';
import Label from '../atoms/Label';
import Button from '../atoms/Button';
import Icon from '../atoms/Icon';
import InputField from './InputField';

// Component
const ButtonWithInputField = ({
  label,
  name,
  placeholder,
  value,
  type,
  disabled,
  required,
  onChangeFcn,
  buttonOnClickFcn,
  buttonStyleType,
  buttonIcon,
  additionalClassNames
}) => {
  // Setup css classes
  const cssClasses = classNames(
    'button-with-input-field',
    additionalClassNames
  );

  const renderLabel = () => {
    return (
      label && (
        <Label
          htmlFor={name}
          additionalClassNames="button-with-input-field__label"
          text={label}
        />
      )
    );
  };

  return (
    <div className={cssClasses}>
      {renderLabel()}
      <div className="button-with-input-field__wrapper">
        <Button
          onClickFcn={buttonOnClickFcn}
          styleType={buttonStyleType}
          additionalClassNames="button-with-input-field__button"
          disabled={disabled}
        >
          <Icon icon={buttonIcon} />
        </Button>
        <InputField
          onChangeFcn={onChangeFcn}
          disabled={disabled}
          name={name}
          type={type}
          placeholder={placeholder}
          value={value}
          pattern={regexPatterns.geocoordinates}
          required={required}
          additionalClassNames="button-with-input-field__input"
        />
      </div>
    </div>
  );
};

export default ButtonWithInputField;
