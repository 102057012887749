// Vendor imports
import { ApolloClient } from 'apollo-client';
import { InMemoryCache } from 'apollo-cache-inmemory';
import { onError } from 'apollo-link-error';
import { ApolloLink, concat } from 'apollo-link';
import { createUploadLink } from 'apollo-upload-client';

// Custom imports
import { getToken } from '../utilities/authentication';
import config from '../constants/config';

// Setup data endpoint as apollo uploadLink
const httpLinkWithUpload = createUploadLink({ uri: config.dataEndpoint });

// Setup middleware to path an jwt auth token, if present
const authMiddleware = new ApolloLink((operation, forward) => {
  const token = getToken();
  operation.setContext({
    headers: {
      authorization: token ? `Bearer ${token}` : null
    }
  });

  return forward(operation);
});

// Define ApolloClient
const gqlClient = new ApolloClient({
  link: ApolloLink.from([
    onError(({ graphQLErrors, networkError }) => {
      if (graphQLErrors)
        graphQLErrors.forEach(({ message, locations, path }) =>
          console.error(
            `[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`
          )
        );
      if (networkError) console.error(`[Network error]: ${networkError}`);
    }),
    concat(authMiddleware, httpLinkWithUpload)
  ]),
  cache: new InMemoryCache()
});

export default gqlClient;
