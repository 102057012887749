// Vendor imports
import React from 'react';
import classNames from 'classnames';

// Custom imports
import Tag from '../atoms/Tag';

// Component
const TagField = ({
  label,
  name,
  id,
  checked,
  disabled,
  required,
  onChangeFcn,
  additionalClassNames
}) => {
  // Setup css classes
  const cssClasses = classNames('tag-field', additionalClassNames);

  const onChangeHandler = target => {
    // Set label as value if checkbox is checked. Otherwise reset value
    onChangeFcn(target.value);
  };

  return (
    <Tag
      isActive={checked}
      disabled={disabled}
      additionalClassNames={cssClasses}
    >
      <input
        id={id}
        name={name}
        type="checkbox"
        value={label}
        checked={checked}
        onChange={event => onChangeHandler(event.target)}
        disabled={disabled}
        required={required}
        className="tag-field__input"
      />
      <label className="tag-field__label" htmlFor={id}>
        {label}
      </label>
    </Tag>
  );
};

export default TagField;
