// Vendor imports
import React from 'react';
import { useQuery } from '@apollo/react-hooks';

// Custom imports
import Travel from '../presentationals/templates/Travel';
import { TRAVEL_QUERY } from '../../gql/queries';
import { iriPrefixes } from '../../constants/gql';
import { getTravelDate } from '../../utilities/date';
import { getWaypointItems } from '../../utilities/waypoint';
import { buildIdUrl } from '../../utilities/miscellaneous';

const TravelContainer = ({ match, loader }) => {
  // GQL Query: (travel (ID!))
  const { loading, error, data: queryData } = useQuery(TRAVEL_QUERY, {
    // Get travel id from url
    variables: { id: iriPrefixes.travel + match.params.id }
  });

  // If loading, show loading screen
  if (loading) {
    loader.show();
    return null;
  } else {
    loader.hide();
  }

  // If error hide loading screen and show error
  if (error) {
    loader.hide();
    return `Error! ${error.message}`;
  }

  return (
    <Travel
      navigation={{
        title: 'Meine Reisen',
        navigateTo: '/travels',
        icon: 'arrow-left'
      }}
      action={{
        icon: 'edit',
        navigateTo: `${buildIdUrl('/travels/', queryData.travel.id)}/edit`
      }}
      itemList={{
        title: `${queryData.travel.waypoints.length} Zwischenstops`,
        action: {
          icon: 'plus',
          navigateTo: buildIdUrl(
            '/travels/',
            queryData.travel.id,
            '/add-waypoint'
          )
        },
        items: getWaypointItems(queryData.travel.waypoints, queryData.travel.id)
      }}
      title={queryData.travel.title}
      description={queryData.travel.description}
      startDate={
        queryData.travel.waypoints.length
          ? getTravelDate(queryData.travel.waypoints)
          : 'Noch nicht gestartet'
      }
      endDate={
        queryData.travel.waypoints.length
          ? getTravelDate(queryData.travel.waypoints, true)
          : ''
      }
      costs={queryData.travel.costs}
      mapData={{
        items: getWaypointItems(queryData.travel.waypoints, queryData.travel.id)
      }}
    />
  );
};

export default TravelContainer;
