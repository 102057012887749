// Vendor imports
import React from 'react';
import classNames from 'classnames';
import { useLeaflet } from 'react-leaflet';

// Custom imports
import Button from '../atoms/Button';
import Icon from '../atoms/Icon';

// Component
const LocateButton = ({ additionalClassNames }) => {
  // Setup css classes
  const cssClasses = classNames('locate-button', additionalClassNames);

  // Get map elemet
  const { map } = useLeaflet();

  // Use geo location (caution: only works with https!)
  const getCurrentGeoCoordinates = () => {
    map.locate({ setView: true });
  };

  // Render
  return (
    <Button
      onClickFcn={getCurrentGeoCoordinates}
      styleType="no-styles"
      additionalClassNames={cssClasses}
    >
      <Icon
        icon="compass-filled"
        bigCircle={true}
        additionalClassNames="locations__compass-button"
      />
    </Button>
  );
};

export default LocateButton;
