// Vendor imports
import React from 'react';
import classNames from 'classnames';

// Component
const StatusMessage = ({ status, message, additionalClassNames }) => {
  // Setup css classes
  const cssClasses = classNames(
    'status-message',
    {
      'status-message--info': status === 'info',
      'status-message--error': status === 'error'
    },
    additionalClassNames
  );

  return (
    message && (
      <div className={cssClasses}>
        <p className="status-message__message">{message}</p>
      </div>
    )
  );
};

export default StatusMessage;
