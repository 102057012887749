// Vendor imports
import React, { useState } from 'react';

// Custom imports
import Button from '../atoms/Button';
import InputField from '../molecules/InputField';
import TextareaField from '../molecules/TextareaField';
import classNames from 'classnames';

// Component
const TravelForm = ({
  id,
  initialTitle,
  initialDescription,
  initialCosts,
  handleSubmit,
  buttonText,
  additionalClassNames
}) => {
  // Setup css classes
  const cssClasses = classNames('form', additionalClassNames);

  // State definition
  const [title, setTitle] = useState(initialTitle ? initialTitle : '');
  const [description, setDescription] = useState(
    initialDescription ? initialDescription : ''
  );
  const [costs, setCosts] = useState(initialCosts ? initialCosts : '');

  // State updates
  const handleTitleInputChange = value => {
    setTitle(value);
  };
  const handleDescriptionInputChange = value => {
    setDescription(value);
  };
  const handleCostsInputChange = value => {
    setCosts(value);
  };

  // On travel submit handling
  const onSubmit = event => {
    event.preventDefault();
    handleSubmit(id, title, description, costs);
  };

  return (
    <div className={cssClasses}>
      <form className="form login-form__form" onSubmit={onSubmit}>
        <div className="form__field-group">
          <InputField
            label="Titel"
            onChangeFcn={handleTitleInputChange}
            name="title"
            type="text"
            placeholder="Gib der Reise einen Titel"
            value={title}
            required={true}
            additionalClassNames="form__input-field"
          />
        </div>
        <div className="form__field-group">
          <TextareaField
            label="Beschreibung"
            onChangeFcn={handleDescriptionInputChange}
            name="description"
            rows="10"
            placeholder="Beschreibe die Reise"
            value={description}
            additionalClassNames="form__input-field"
          />
        </div>
        <div className="form__field-group">
          <InputField
            label="Kosten"
            onChangeFcn={handleCostsInputChange}
            name="costs"
            type="number"
            min="0.00"
            step="0.01"
            placeholder="Sind kosten angefallen?"
            value={costs}
            additionalClassNames="form__input-field"
          />
        </div>
        <div className="form__field-group">
          <Button
            type="submit"
            additionalClassNames="form__button"
            fullWidth={true}
          >
            {buttonText}
          </Button>
        </div>
      </form>
    </div>
  );
};

export default TravelForm;
