const config = {
  authEndpoint: process.env.REACT_APP_AUTH_ENDPOINT,
  dataEndpoint: process.env.REACT_APP_DATA_ENDPOINT,
  locationImageEndpoint: process.env.REACT_APP_LOCATION_IMAGE_ENDPOINT,
  openRouteService: {
    apiKey: process.env.REACT_APP_OPENROUTESERVICE_APIKEY
  }
};

export default config;
