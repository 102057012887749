import gql from 'graphql-tag';

export const ALL_LOCATIONS_QUERY = gql`
  {
    locations(
      type_list: [
        "pitch"
        "campsite"
        "wildCamping"
        "publicParkingSpace"
        "privatParkingSpace"
        "misc"
      ]
    ) {
      id
      user {
        id
        name
      }
      title
      type
      services
      description
      latitude
      longitude
      rating
      prices
    }
  }
`;

export const LOCATION_QUERY = gql`
  query Location($id: ID!) {
    location(id: $id) {
      id
      user {
        id
        name
      }
      title
      type
      services
      description
      image {
        fileName
      }
      latitude
      longitude
      rating
      prices
    }
  }
`;

export const LOCATIONS_NEARBY = gql`
  query LocationsNearby($latitudeRange: String!, $longitudeRange: String!) {
    locations(
      latitude: { between: $latitudeRange }
      longitude: { between: $longitudeRange }
    ) {
      id
      title
      type
      services
      description
      image {
        fileName
      }
      rating
      prices
      latitude
      longitude
      user {
        id
      }
    }
  }
`;

export const USER_TRAVELS_QUERY = gql`
  query User($id: ID!) {
    user(id: $id) {
      travels {
        id
        title
        waypoints {
          id
          arrivalDate
          location {
            id
            title
            type
            services
            longitude
            latitude
            prices
          }
        }
      }
    }
  }
`;

export const TRAVEL_QUERY = gql`
  query Travel($id: ID!) {
    travel(id: $id) {
      id
      title
      description
      costs
      waypoints {
        id
        arrivalDate
        location {
          id
          title
          type
          services
          latitude
          longitude
          prices
        }
      }
    }
  }
`;

export const WAYPOINT_QUERY = gql`
  query Waypoint($id: ID!) {
    waypoint(id: $id) {
      id
      arrivalDate
      travel {
        id
      }
      location {
        id
        user {
          id
          name
        }
        title
        type
        services
        description
        image {
          id
          fileName
        }
        latitude
        longitude
        rating
        prices
        numberOfWaypoints
      }
    }
  }
`;
