// Vendor imports
import React from 'react';
import classNames from 'classnames';

// Custom imports
import Label from '../atoms/Label';

// Component
const InputField = ({
  label,
  name,
  type,
  placeholder,
  value,
  disabled,
  required,
  step,
  pattern,
  min,
  max,
  onChangeFcn,
  onBlurFcn,
  additionalClassNames
}) => {
  // Setup css classes
  const cssClasses = classNames('input-field', additionalClassNames);

  const renderLabel = () => {
    return (
      label && (
        <Label htmlFor={name} className="input-field__label" text={label} />
      )
    );
  };

  return (
    <div className={cssClasses}>
      {renderLabel()}
      <input
        id={name}
        name={name}
        type={type}
        step={step}
        pattern={pattern}
        min={min}
        max={max}
        placeholder={placeholder}
        value={value}
        onChange={event => onChangeFcn && onChangeFcn(event.target.value)}
        onBlur={event => onBlurFcn && onBlurFcn(event.target.value)}
        disabled={disabled}
        required={required}
        className="input-field__input"
      />
    </div>
  );
};

export default InputField;
