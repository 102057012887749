// Vendor imports
import { push } from 'connected-react-router';
import {
  login,
  resetToken,
  getToken,
  getTokenData
} from '../utilities/authentication';

// Custom imports
import statusMessages from '../constants/statusMessages';

// Actions
export const IS_AUTHENTICATED = 'AUTHENTICATED_USER';
export const IS_UNAUTHENTICATED = 'UNAUTHENTICATED_USER';
export const IS_AUTHENTICATION_EXPIRED = 'EXPIRED_USERSESSION';
export const HAS_AUTHENTICATION_ERROR = 'AUTHENTICATION_ERROR';

// Thunk action creator function
export function loginAction(email, password) {
  return dispatch => {
    login(email, password)
      // If request is successful dispatch IS_AUTHENTICATED action
      .then(() => {
        // Get token
        const token = getToken();
        // Get token data
        const tokenData = getTokenData(token);

        // Dispatch IS_AUTHENTICATED
        dispatch({
          type: IS_AUTHENTICATED,
          ...tokenData
        });

        // Redirect to app startpage
        dispatch(push('/'));
      })
      // If request throws an error dispatch HAS_AUTHENTICATION_ERROR action
      .catch(() => {
        // Dispatch HAS_AUTHENTICATION_ERROR
        dispatch({
          type: HAS_AUTHENTICATION_ERROR,
          authErrorMessage: statusMessages.error.authentication
        });
      });
  };
}

export function logoutAction() {
  // delete token from local storage
  resetToken();

  // Dispatch IS_UNAUTHENTICATED
  return {
    type: IS_UNAUTHENTICATED
  };
}
