import React from 'react';

// TODO: Setup presentational component
const NotFoundContainer = () => {
  return (
    <div>
      <h1>404: Uuuups, page not found!</h1>
    </div>
  );
};

export default NotFoundContainer;
