// Vendor imports
import React, { useState } from 'react';
import classNames from 'classnames';
import { useDispatch } from 'react-redux';
import { push } from 'connected-react-router';

// Custom imports
import InputField from '../molecules/InputField';
import Button from '../atoms/Button';

// Component
const LoginForm = ({ handleSubmit, authenticated, additionalClassNames }) => {
  // State
  const dispatch = useDispatch();
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const handleEMailInputChange = value => {
    setEmail(value);
  };
  const handlePasswordInputChange = value => {
    setPassword(value);
  };

  // Setup css classes
  const cssClasses = classNames('form', additionalClassNames);

  // Conditional button text
  const buttonText = authenticated ? 'Zurück zur App' : 'Einloggen';

  // login-form submit handling
  const onSubmit = event => {
    if (authenticated) {
      dispatch(push('/'));
    } else {
      handleSubmit(event, email, password);
    }
  };

  return (
    <div className={cssClasses}>
      <form className="form login-form__form" onSubmit={onSubmit}>
        <div className="form__field-group">
          <InputField
            onChangeFcn={handleEMailInputChange}
            name="email"
            type="text"
            placeholder="Your e-mail address"
            value={email}
            disabled={authenticated}
            required={!authenticated}
            additionalClassNames="form__input-field"
          />
        </div>
        <div className="form__field-group">
          <InputField
            onChangeFcn={handlePasswordInputChange}
            name="passwort"
            type="password"
            placeholder="Your Password"
            value={password}
            disabled={authenticated}
            required={!authenticated}
            additionalClassNames="form__input-field"
          />
        </div>
        <div className="form__field-group">
          <Button
            type="submit"
            additionalClassNames="form__button"
            fullWidth={true}
          >
            {buttonText}
          </Button>
        </div>
      </form>
    </div>
  );
};

export default LoginForm;
