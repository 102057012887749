// Vendor imports
import React from 'react';
import classNames from 'classnames';
import Label from '../atoms/Label';

// Component
const TextareaField = ({
  label,
  name,
  rows,
  placeholder,
  value,
  disabled,
  required,
  defaultText,
  onChangeFcn,
  additionalClassNames
}) => {
  // Setup css classes
  const cssClasses = classNames('textarea-field', additionalClassNames);

  const renderLabel = () => {
    return (
      label && (
        <Label htmlFor={name} className="textarea-field__label" text={label} />
      )
    );
  };

  return (
    <div className={cssClasses}>
      {renderLabel()}
      <textarea
        name={name}
        rows={rows}
        placeholder={placeholder}
        value={value}
        onChange={event => onChangeFcn(event.target.value)}
        disabled={disabled}
        required={required}
        className="textarea-field__textarea"
      />
    </div>
  );
};

export default TextareaField;
