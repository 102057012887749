// Vendor imports
import React from 'react';
import classNames from 'classnames';

// Component
const Divider = ({ additionalClassNames }) => {
  // Setup css classes
  const cssClasses = classNames('divider', additionalClassNames);

  return <hr className={cssClasses} />;
};

export default Divider;
