import L from 'leaflet';
import 'leaflet-routing-machine';
import polyline from '@mapbox/polyline';
import openrouteservice from 'openrouteservice-js';

(function() {
  L.Routing = L.Routing || {};

  L.Routing.OpenRouteService = L.Class.extend({
    initialize: function(apiKey) {
      this._apiKey = apiKey;
    },
    route: function(waypoints, callback, context) {
      let leafletWaypoints = [];
      let openRouteServiceWaypoints = [];

      // Prepare leaflet waypoints & openRouteService waypoints
      waypoints.forEach(waypoint => {
        leafletWaypoints.push({
          latLng: waypoint.latLng,
          name: waypoint.name,
          options: waypoint.options
        });
        openRouteServiceWaypoints.push([
          waypoint.latLng.lng,
          waypoint.latLng.lat
        ]);
      });

      // OpenRouteService routing data request
      let fetchRoutingData = new openrouteservice.Directions({
        api_key: this._apiKey
      });

      fetchRoutingData
        .calculate({
          coordinates: openRouteServiceWaypoints,
          profile: 'driving-car',
          format: 'geojson',
          language: 'de',
          units: 'km'
        })
        .then(response => {
          this._processRoutingData(
            response,
            leafletWaypoints,
            callback,
            context
          );
        })
        .catch(err => {
          callback.call(context || callback, {
            status: -1,
            message: 'Request failed: ' + err
          });
        });
      return this;
    },
    _processRoutingData: function(
      routingData,
      inputWaypoints,
      callback,
      context
    ) {
      let routes = [];

      // Set context
      context = context || callback;

      // Throw an error if no route could be returned by the service
      if (!routingData.features) {
        callback.call(context, {
          status: routingData.error.code,
          message: routingData.error.message
        });
        return;
      }

      // Get routes
      routingData.features.length &&
        routingData.features.forEach((feature, index) => {
          let distance = 0;
          let time = 0;
          const instructions = [];
          const coordinates = this._decodePolyline(feature.geometry);

          // Get distance, time, instructions
          feature.properties.segments.forEach(segment => {
            segment.steps.forEach(step => {
              distance += step.distance;
              time += step.duration;
              instructions.push(this._convertInstructions(step));
            });
          });

          // Add route to routes array
          routes.push({
            name: 'Route ' + index,
            coordinates: coordinates,
            instructions: instructions,
            summary: {
              totalDistance: distance,
              totalTime: time
            },
            inputWaypoints: inputWaypoints,
            waypoints: coordinates[feature.properties.way_points[1]]
          });
        });

      // Call callback function
      callback.call(context, null, routes);
    },
    _decodePolyline: function(geometry) {
      const latlngs = [];
      const polylineDefined = polyline.fromGeoJSON(geometry);
      const coords = polyline.decode(polylineDefined, 5);

      coords.forEach(choord => {
        latlngs.push(new L.LatLng(choord[0], choord[1]));
      });

      return latlngs;
    },
    _convertInstructions: function(step) {
      return {
        text: step.instruction,
        distance: step.distance,
        time: step.duration,
        index: step.way_points[0]
      };
    }
  });

  L.Routing.openrouteservice = function(apiKey) {
    return new L.Routing.OpenRouteService(apiKey);
  };
})();
