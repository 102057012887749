const statusMessages = {
  error: {
    authentication:
      'Benutzer-Name oder Passwort sind nicht korrekt. Probier es nochmal',
    sessionExpired: 'Deine Sitzung ist abgelaufen. Meld Dich erneut an!'
  },
  alreadyLoggedIn: 'Du bist bereits eingelogged!'
};

export default statusMessages;
