// Vendor imports
import React from 'react';
import { Breakpoint } from 'react-socks';

// Custom imports
import NavigationHeader from '../molecules/NavigationHeader';
import ItemList from '../organisms/ItemList';
import Map from '../organisms/Map';
import LocationPopupContent from '../molecules/LocationPopupContent';
import { Marker, Popup } from 'react-leaflet';
import {
  baseMarkerIcon,
  getLocationMapBounds,
  getMapCenterPosition,
  getMapZoomLevel
} from '../../../utilities/map';
import { locationTypes } from '../../../constants/location';

const Travels = ({ navigation, action, itemList, mapData }) => {
  return (
    <div className="travels">
      <main className="main travels__main">
        <div className="main__col main__col--left">
          <NavigationHeader navigation={navigation} action={action} />
          <div className="main__text-group">
            <h1 className="main__text-group-h1">Meine Reisen</h1>
          </div>
          <ItemList items={itemList.items} />
        </div>
        <Breakpoint medium up className="main__col main__col--right">
          <Map
            center={getMapCenterPosition(mapData.items)}
            bounds={
              mapData.items.length > 1
                ? getLocationMapBounds(mapData.items)
                : null
            }
            zoom={getMapZoomLevel(mapData.items, 7, 5)}
            additionalClassNames="travels__map"
          >
            {mapData.items.length &&
              mapData.items.map(waypoint => {
                return (
                  <Marker
                    key={waypoint.id}
                    position={[waypoint.latitude, waypoint.longitude]}
                    icon={baseMarkerIcon}
                  >
                    <Popup minWidth="240" maxWidth="240">
                      <LocationPopupContent
                        title={waypoint.title}
                        type={waypoint.type && locationTypes[waypoint.type]}
                        subtitle={waypoint.subtitle}
                        prices={waypoint.prices}
                        url={waypoint.url}
                      />
                    </Popup>
                  </Marker>
                );
              })}
          </Map>
        </Breakpoint>
      </main>
    </div>
  );
};

export default Travels;
