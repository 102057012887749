// Vendor imports
import React from 'react';
import { useQuery } from '@apollo/react-hooks';

// Custom imports
import Location from '../presentationals/templates/Location';
import { iriPrefixes } from '../../constants/gql';
import { WAYPOINT_QUERY } from '../../gql/queries';
import { getFormatedDateFromMysqlDatetime } from '../../utilities/date';
import config from '../../constants/config';
import { locationTypes } from '../../constants/location';

// Component
const WaypointContainer = ({ match, loader }) => {
  // GQL Query: (waypoint (ID!))
  const { loading, error, data } = useQuery(WAYPOINT_QUERY, {
    variables: { id: iriPrefixes.waypoints + match.params.id }
  });

  // If loading, show loading screen
  if (loading) {
    loader.show();
    return null;
  } else {
    loader.hide();
  }

  // If error hide loading screen and show error
  if (error) {
    loader.hide();
    return `Error! ${error.message}`;
  }

  // Render
  return (
    <Location
      title={data.waypoint.location.title}
      type={
        data.waypoint.location.type &&
        locationTypes[data.waypoint.location.type]
      }
      services={
        data.waypoint.location.services &&
        data.waypoint.location.services.split(',')
      }
      description={data.waypoint.location.description}
      image={
        data.waypoint.location.image
          ? config.locationImageEndpoint + data.waypoint.location.image.fileName
          : undefined
      }
      latitude={data.waypoint.location.latitude}
      longitude={data.waypoint.location.longitude}
      rating={data.waypoint.location.rating}
      prices={data.waypoint.location.prices}
      userName={data.waypoint.location.user.name}
      arrivalDate={getFormatedDateFromMysqlDatetime(data.waypoint.arrivalDate)}
      navigation={{
        title: 'zurück zur Reise',
        navigateTo: `/travels/${match.params.travelId}`
      }}
      action={{
        navigateTo: `${match.url}/edit`,
        icon: 'edit'
      }}
    />
  );
};

export default WaypointContainer;
