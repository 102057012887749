// Vendor imports
import React from 'react';
import classNames from 'classnames';
import { Link } from 'react-router-dom';

// Custom imports
import Icon from '../atoms/Icon';

// Component
const ItemListContent = ({ title, subtitle, url, additionalClassNames }) => {
  // Setup css classes
  const cssClasses = classNames('item-list-content', additionalClassNames);

  return (
    <section className={cssClasses}>
      <Link className="item-list-content__link" to={url}>
        <div className="item-list-content__text-group">
          <h1 className="item-list-content__header">{title}</h1>
          <h2 className="item-list-content__sub-header">{subtitle}</h2>
        </div>
        <Icon
          icon="arrow-right"
          additionalClassNames="item-list-content__icon"
        />
      </Link>
    </section>
  );
};

export default ItemListContent;
