// Vendor imports
import React from 'react';
import { Marker, Popup } from 'react-leaflet';
import { Breakpoint } from 'react-socks';

// custom imports
import ItemList from '../organisms/ItemList';
import Map from '../organisms/Map';
import NavigationHeader from '../molecules/NavigationHeader';
import Divider from '../atoms/Divider';
import LocationPopupContent from '../molecules/LocationPopupContent';
import Routing from '../molecules/Routing';
import {
  baseMarkerIcon,
  getLocationMapBounds,
  getMapCenterPosition,
  getMapZoomLevel
} from '../../../utilities/map';
import { locationTypes } from '../../../constants/location';

const Travel = ({
  navigation,
  action,
  itemList,
  title,
  startDate,
  endDate,
  description,
  costs,
  mapData
}) => {
  return (
    <div className="travel">
      <main className="main">
        <div className="main__col main__col--left">
          <NavigationHeader navigation={navigation} action={action} />
          <div className="main__text-group travel__text-group">
            <h1 className="travel__title">{title}</h1>
            <ul className="travel__list">
              <li className="travel__list">
                Reise-Daten: {endDate ? `${startDate} - ${endDate}` : startDate}
              </li>
              <li className="travel__list">
                Reise-Kosten:
                {costs && costs !== '0.00' ? ` ${costs} €` : ' keine Angabe'}
              </li>
              <li className="travel__list">
                Zwischenstops: {itemList.items.length}
              </li>
            </ul>

            <Divider />

            <h2>Beschreibung</h2>
            <p className="travel__description">{description}</p>
          </div>
          <ItemList
            items={itemList.items}
            header={{
              title: itemList.title,
              action: {
                icon: itemList.action.icon,
                navigateTo: itemList.action.navigateTo
              }
            }}
          />
        </div>
        <Breakpoint className="main__col main__col--right" medium up>
          <Map
            center={getMapCenterPosition(mapData.items)}
            bounds={
              mapData.items.length > 1
                ? getLocationMapBounds(mapData.items)
                : null
            }
            zoom={getMapZoomLevel(mapData.items, 7, 5)}
            additionalClassNames="travel__map"
          >
            {mapData.items.length > 1 && (
              <Routing items={mapData.items} color="#239d61" />
            )}

            {mapData.items.length &&
              mapData.items.map(waypoint => {
                return (
                  <Marker
                    key={waypoint.id}
                    position={[waypoint.latitude, waypoint.longitude]}
                    icon={baseMarkerIcon}
                  >
                    <Popup minWidth="240" maxWidth="240">
                      <LocationPopupContent
                        title={waypoint.title}
                        type={waypoint.type && locationTypes[waypoint.type]}
                        subtitle={waypoint.subtitle}
                        prices={waypoint.prices}
                        url={waypoint.url}
                      />
                    </Popup>
                  </Marker>
                );
              })}
          </Map>
        </Breakpoint>
      </main>
    </div>
  );
};

export default Travel;
