import gql from 'graphql-tag';

export const CREATE_TRAVEL = gql`
  mutation CreateTravel(
    $user: String!
    $title: String!
    $description: String
    $costs: String
  ) {
    createTravel(
      input: {
        user: $user
        title: $title
        description: $description
        costs: $costs
      }
    ) {
      travel {
        id
      }
    }
  }
`;

export const UPDATE_TRAVEL = gql`
  mutation UpdateTravel(
    $id: ID!
    $title: String!
    $description: String
    $costs: String
  ) {
    updateTravel(
      input: {
        id: $id
        title: $title
        description: $description
        costs: $costs
      }
    ) {
      travel {
        id
      }
    }
  }
`;

export const DELETE_TRAVEL = gql`
  mutation DeleteTravel($id: ID!) {
    deleteTravel(input: { id: $id }) {
      travel {
        id
      }
    }
  }
`;

export const CREATE_LOCATION = gql`
  mutation CreateLocation(
    $user: String!
    $title: String!
    $type: String!
    $services: String
    $description: String!
    $image: String
    $latitude: String!
    $longitude: String!
    $rating: Int!
    $prices: String
  ) {
    createLocation(
      input: {
        user: $user
        title: $title
        type: $type
        services: $services
        description: $description
        image: $image
        latitude: $latitude
        longitude: $longitude
        rating: $rating
        prices: $prices
      }
    ) {
      location {
        id
      }
    }
  }
`;

export const UPLOAD_MEDIA_OBJECT = gql`
  mutation uploadMediaObject($file: Upload!) {
    uploadMediaObject(input: { file: $file }) {
      mediaObject {
        id
        fileName
      }
    }
  }
`;

export const DELETE_MEDIA_OBJECT = gql`
  mutation DeleteMediaObject($id: ID!) {
    deleteMediaObject(input: { id: $id }) {
      mediaObject {
        id
      }
    }
  }
`;

export const CREATE_WAYPOINT = gql`
  mutation CreateWaypoint(
    $userId: String!
    $travelId: String!
    $locationId: String!
    $arrivalDate: String!
  ) {
    createWaypoint(
      input: {
        user: $userId
        travel: $travelId
        location: $locationId
        arrivalDate: $arrivalDate
      }
    ) {
      waypoint {
        id
      }
    }
  }
`;

export const DELETE_WAYPOINT = gql`
  mutation DeleteWayoint($id: ID!) {
    deleteWaypoint(input: { id: $id }) {
      waypoint {
        id
      }
    }
  }
`;

export const UPDATE_WAYPOINT_AND_LOCATION = gql`
  mutation UpdateWaypointandLocation(
    $waypointId: ID!
    $arrivalDate: String!
    $locationId: ID!
    $title: String!
    $type: String!
    $services: String
    $description: String!
    $image: String
    $latitude: String!
    $longitude: String!
    $rating: Int!
    $prices: String
  ) {
    updateWaypoint(input: { id: $waypointId, arrivalDate: $arrivalDate }) {
      waypoint {
        id
      }
    }
    updateLocation(
      input: {
        id: $locationId
        title: $title
        type: $type
        services: $services
        description: $description
        image: $image
        latitude: $latitude
        longitude: $longitude
        rating: $rating
        prices: $prices
      }
    ) {
      location {
        id
      }
    }
  }
`;

export const DELETE_WAYPOINT_AND_LOCATION = gql`
  mutation DeleteWaypointAndConnectedLocation(
    $waypointId: ID!
    $locationId: ID!
  ) {
    deleteWaypoint(input: { id: $waypointId }) {
      waypoint {
        id
      }
    }
    deleteLocation(input: { id: $locationId }) {
      location {
        id
      }
    }
  }
`;
