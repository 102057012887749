// Custom imports
import {
  IS_AUTHENTICATED,
  IS_UNAUTHENTICATED,
  HAS_AUTHENTICATION_ERROR,
  IS_AUTHENTICATION_EXPIRED
} from '../actions/authActions';
import statusMessages from '../constants/statusMessages';

function authReducer(
  state = {},
  { type, authErrorMessage, exp, iat, roles, username, id }
) {
  switch (type) {
    case IS_AUTHENTICATED:
      return {
        ...state,
        authenticated: true,
        authErrorMessage: null,
        exp,
        iat,
        roles,
        username,
        id
      };
    case HAS_AUTHENTICATION_ERROR:
      return {
        ...state,
        authenticated: false,
        authErrorMessage
      };
    case IS_UNAUTHENTICATED:
      return {
        ...state,
        authenticated: false,
        authErrorMessage: null,
        exp: null,
        iat: null,
        roles: [],
        username: null,
        id: null
      };
    case IS_AUTHENTICATION_EXPIRED:
      return {
        ...state,
        authenticated: false,
        authErrorMessage: statusMessages.error.sessionExpired,
        exp: null,
        iat: null,
        roles: [],
        username: null,
        id: null
      };
    default:
  }
  return state;
}

export default authReducer;
