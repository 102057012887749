// Vendor imports
import React from 'react';
import classNames from 'classnames';

// Custom imports
import Label from '../atoms/Label';
import TagField from './TagField';
import { slugify } from './../../../utilities/miscellaneous';

// Component
const TagListField = ({
  label,
  name,
  value,
  disabled,
  required,
  onChangeFcn,
  tagFields,
  additionalClassNames
}) => {
  // Setup css classes
  const cssClasses = classNames('tag-list-field', additionalClassNames);

  const onChangeHandler = changedValue => {
    let updatedValues = [];
    const currentValues = value ? value.split(',') : [];
    // Get the index of changedValue in currentValues array.
    // Returns -1 if changedValue is not present in currentValues array
    const changedValueIndex = currentValues.indexOf(changedValue);

    // Add new value
    if (changedValueIndex === -1) {
      currentValues.splice(currentValues.length, 0, changedValue);
      updatedValues = currentValues.sort();
    }

    // Remove changedValue
    else {
      currentValues.splice(changedValueIndex, 1);
      updatedValues = currentValues.toString();
    }

    // Update changedValue
    onChangeFcn(updatedValues.toString());
  };

  const renderLabel = () => {
    return (
      label && (
        <Label htmlFor={name} className="tag-list-field__label" text={label} />
      )
    );
  };

  return (
    <div className={cssClasses}>
      {renderLabel()}

      <ul className="tag-list">
        {tagFields.map(tagField => {
          // Build slug used for id and key
          const slug = slugify(tagField);
          // Set checked property if this tagField was fount in value
          const checked = value ? value.split(',').includes(tagField) : false;

          return (
            <li className="tag-list__list-item" key={slug}>
              <TagField
                label={tagField}
                onChangeFcn={changedValue => onChangeHandler(changedValue)}
                name={name}
                id={slug}
                checked={checked}
                required={required}
                disabled={disabled}
                additionalClassNames="tag-list__tag"
              />
            </li>
          );
        })}
      </ul>
    </div>
  );
};

export default TagListField;
