// Vendor imports
import React, { useState } from 'react';
import classNames from 'classnames';
import { Map as LeavletMap, Marker, TileLayer } from 'react-leaflet';

// Custom imports
import { mylocationMarkerIcon } from '../../../utilities/map';
import { useGeolocation } from '../../../hooks/useGeolocation';

// Component
const Map = ({
  bounds,
  zoom,
  center,
  children,
  additionalClassNames,
  onClickFcn
}) => {
  // Setup css classes
  const cssClasses = classNames('map', additionalClassNames);
  const [currentPosition] = useGeolocation();

  // Local state definition
  const [zoomLevel, setZoomLevel] = useState(zoom);

  // Respect manual zooming
  const handleMapZoom = event => {
    setZoomLevel(event.target._zoom);
  };

  return (
    <LeavletMap
      className={cssClasses}
      bounds={bounds}
      center={center}
      zoom={zoomLevel}
      tap={true}
      touchZoom={true}
      animate={true}
      zoomControl={false}
      onClick={onClickFcn}
      onZoomend={handleMapZoom}
      minZoom={3}
      maxZoom={19}
    >
      <TileLayer
        attribution='&amp;copy <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
        url="https://{s}.tile.openstreetmap.de/tiles/osmde/{z}/{x}/{y}.png"
      />

      {currentPosition && (
        <Marker
          position={[
            currentPosition.coords.latitude,
            currentPosition.coords.longitude
          ]}
          icon={mylocationMarkerIcon}
          zIndexOffset={-1000}
        />
      )}

      {children}
    </LeavletMap>
  );
};

export default Map;
