// Vendor imports
import React from 'react';
import { Marker } from 'react-leaflet';
import { Breakpoint } from 'react-socks';

// Custom imports
import NavigationHeader from '../molecules/NavigationHeader';
import Rating from '../atoms/Rating';
import Divider from '../atoms/Divider';
import TagList from '../molecules/TagList';
import Map from '../organisms/Map';
import { baseMarkerIcon } from '../../../utilities/map';
import { getMobileOs } from '../../../utilities/miscellaneous';
const Location = ({
  navigation,
  action,
  title,
  type,
  services,
  description,
  image,
  latitude,
  longitude,
  rating,
  prices,
  userName,
  arrivalDate
}) => {
  // Render arrivalDate of waypoint only if it's available since this component is used to display locations (without arrivalDate) and waypoints
  const renderArrivalDate = () => {
    return (
      arrivalDate && (
        <small className="location__arrival-date">{arrivalDate}</small>
      )
    );
  };

  const renderTagList = () => {
    return (
      services &&
      services.length && (
        <li className="location__list-element">
          <span className="location__list-element-label">Services:</span>
          <TagList tags={services} />
        </li>
      )
    );
  };

  const createGeolocationLink = () => {
    switch (getMobileOs()) {
      case 'android':
        return `geo:${latitude},${longitude}`;
      case 'ios':
        return `https://maps.apple.com/?q=${title}&ll=${latitude},${longitude}`;
      default:
        return `https://www.google.com/maps/search/?api=1&query=${latitude},${longitude}`;
    }
  };

  return (
    <div className="location">
      <main className="main location__main">
        <div className="main__col main__col--left">
          <NavigationHeader
            navigation={{
              title: navigation.title,
              navigateTo: navigation.navigateTo,
              icon: 'arrow-left'
            }}
            action={{
              icon: action ? action.icon : '',
              navigateTo: action ? action.navigateTo : ''
            }}
          />
          <div className="location__image-and-rating">
            <img
              className="location__image"
              src={
                image
                  ? image
                  : 'https://dummyimage.com/766x431/cfcfcf/f8f8f8&text=No+Image'
              }
              alt=""
            />
            <Rating rating={rating} additionalClassNames="location__rating" />
          </div>

          <div className="location__text-group">
            {renderArrivalDate()}
            <h1 className="location__title">{title}</h1>

            <ul className="location__list">
              <li className="location__list-element">
                <span className="location__list-element-label">
                  Typ: {type}
                </span>
              </li>
              <li className="location__list-element">
                <span className="location__list-element-label">
                  Koordinaten:{' '}
                </span>
                <a
                  className="location__list-element-link"
                  href={createGeolocationLink()}
                  rel="noopener noreferrer"
                  target="_blank"
                >
                  <span className="location__list-element-geo-coordinate">
                    {latitude}
                  </span>
                  <span className="location__list-element-geo-coordinate">
                    {', '}
                  </span>
                  <span className="location__list-element-geo-coordinate">
                    {longitude}
                  </span>
                </a>
              </li>
              <li className="location__list-element">
                <span className="location__list-element-label">Kosten: </span>
                {prices}
              </li>
              <li className="location__list-element">
                <span className="location__list-element-label">
                  Eingetragen von:
                </span>
                {userName}
              </li>
              {renderTagList()}
            </ul>

            <Divider />

            <h2>Beschreibung</h2>
            <p className="location__description">{description}</p>
          </div>
        </div>
        <Breakpoint medium up className="main__col main__col--right">
          <Map
            center={[latitude, longitude]}
            zoom={12}
            additionalClassNames="location__map"
          >
            <Marker position={[latitude, longitude]} icon={baseMarkerIcon} />
          </Map>
        </Breakpoint>
      </main>
    </div>
  );
};

export default Location;
