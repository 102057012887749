// Vendor imports
import React from 'react';
import { useQuery } from '@apollo/react-hooks';

// Custom imports
import Location from '../presentationals/templates/Location';
import { LOCATION_QUERY } from '../../gql/queries';
import { iriPrefixes } from '../../constants/gql';
import config from '../../constants/config';
import { locationTypes } from '../../constants/location';

// Component
const LocationContainer = ({ match, loader }) => {
  // Fetch data
  const { loading, error, data } = useQuery(LOCATION_QUERY, {
    variables: { id: iriPrefixes.location + match.params.id }
  });

  // If loading, show loading screen
  if (loading) {
    loader.show();
    return null;
  } else {
    loader.hide();
  }

  // If error hide loading screen and show error
  if (error) {
    loader.hide();
    return `Error! ${error.message}`;
  }

  return (
    <Location
      title={data.location.title}
      type={data.location.type && locationTypes[data.location.type]}
      description={data.location.description}
      image={
        data.location.image
          ? config.locationImageEndpoint + data.location.image.fileName
          : undefined
      }
      latitude={data.location.latitude}
      longitude={data.location.longitude}
      rating={data.location.rating}
      prices={data.location.prices}
      userName={data.location.user.name}
      navigation={{
        title: 'Orte',
        navigateTo: '/'
      }}
    />
  );
};

export default LocationContainer;
