import { regexPatterns } from '../constants/miscellaneous';

/**
 * Get geolocation from geolocation api of the browser and pass the result
 * it to a callback function.
 * Errors will be printed on the console.
 *
 * @param {function(string)} callback - A callback to run whose signature is
 *   (geolocationRequestResult), where geolocationRequestResult is the resulting
 *   object of the request
 * @return {{}} - geolocationRequestResult
 */

export const requestGeolocation = callback => {
  // Setup options for geolocation
  const options = {
    enableHighAccuracy: true,
    timeout: 10000,
    maximumAge: 0
  };

  // On request success update input field
  function success(geocoordinates) {
    callback(geocoordinates);
  }

  // On error show error messag on the console
  function error(err) {
    console.warn(`ERROR(${err.code}): ${err.message}`);
  }

  // Request geocoordinates
  navigator.geolocation.getCurrentPosition(success, error, options);
};

/**
 * Test if the format of a string matches the format of a
 * geolocation string (lat,long)
 *
 * @param {string} geolocationData - the input string to test
 */

export const testGeocoordinatesFormat = geolocationData => {
  return (
    geolocationData &&
    RegExp(regexPatterns.geocoordinates).test(geolocationData)
  );
};

/**
 * Calculate latitude offset from meters to degrees
 * See: https://gis.stackexchange.com/questions/2951/algorithm-for-offsetting-a-latitude-longitude-by-some-amount-of-meters
 *
 * @param {number} offsetInMeters - Desired offset in meters
 * @return {string} latitude - offset expressed as degree
 */
export const getLatitudeOffset = offsetInMeters =>
  parseFloat((offsetInMeters / 111111).toFixed(15));

/**
 * Calculate longitude offset from meters to degrees
 * See: https://gis.stackexchange.com/questions/2951/algorithm-for-offsetting-a-latitude-longitude-by-some-amount-of-meters
 *
 * @param {number} offsetInMeters - Desired offset in meters
 * @param {float} latitude - Needed to calculate longitude offset
 * @return {string} longitude - offset expressed as degree
 */
export const getLongitudeOffset = (offsetInMeters, latitude) =>
  parseFloat(
    (offsetInMeters / (111111 * Math.cos(parseFloat(latitude)))).toFixed(15)
  );

/**
 * Get latitude range in Format: latitude..longitude
 *
 * @param {number} offsetInMeters - Desired offset in meters
 * @param {float} latitude - geocoordinate: latitude
 * @return {string} range - latitude..longitude
 */

export const getLatitudeRange = (offsetInMeters, latitude) => {
  let from = parseFloat(latitude) - getLatitudeOffset(offsetInMeters);
  let to = parseFloat(latitude) + getLatitudeOffset(offsetInMeters);

  // garanty that from is smaller than to
  if (from > to) {
    to = [from, (from = to)][0];
  }

  return `${from}..${to}`;
};

/**
 * Get longitude range in Format: latitude..longitude
 *
 * @param {number} offsetInMeters - Desired offset in meters
 * @param {float} latitude - geocoordinate: latitude
 * @param {float} longitude - geocoordinate: longitude
 * @return {string} longitude range
 */
export const getLongitudeRange = (offsetInMeters, latitude, longitude) => {
  let from =
    parseFloat(longitude) - getLongitudeOffset(offsetInMeters, latitude);
  let to = parseFloat(longitude) + getLongitudeOffset(offsetInMeters, latitude);

  // garanty that from is smaller than to
  if (from > to) {
    to = [from, (from = to)][0];
  }

  return `${from}..${to}`;
};
