// Vendor imports
import React from 'react';
import { useQuery } from '@apollo/react-hooks';

// Custom imports
import { ALL_LOCATIONS_QUERY } from '../../gql/queries';
import Locations from '../presentationals/templates/Locations';

// Component
const LocationsContainer = ({ loader }) => {
  // Fetch data
  const { loading, error, data } = useQuery(ALL_LOCATIONS_QUERY);

  // If loading, show loading screen
  if (loading) {
    loader.show();
    return null;
  } else {
    loader.hide();
  }

  // If error hide loading screen and show error
  if (error) {
    loader.hide();
    return `Error! ${error.message}`;
  }

  return (
    <Locations
      loader={loader}
      loading={loading}
      locations={data.locations}
      navigation={{
        titel: 'Meine Reisen',
        navigateTo: '/travels'
      }}
    />
  );
};

export default LocationsContainer;
