// Vendor imports
import React from 'react';
import { push } from 'connected-react-router';
import { useQuery, useMutation } from '@apollo/react-hooks';
import { useDispatch, useSelector } from 'react-redux';

// Custom imports
import TravelEdit from '../presentationals/templates/TravelEdit';
import { TRAVEL_QUERY, USER_TRAVELS_QUERY } from '../../gql/queries';
import { UPDATE_TRAVEL, DELETE_TRAVEL } from '../../gql/mutations';
import { iriPrefixes } from '../../constants/gql';
import { getWaypointItems } from '../../utilities/waypoint';

// Prepare state mapping
const mapState = state => ({
  userId: state.user.id
});

// Component
const TravelEditContainer = ({ match, loader }) => {
  // Get user id from redux store
  const { userId } = useSelector(mapState);

  // Initialize hook to update redux store
  const dispatch = useDispatch();

  // GQL Query: (travel (ID!))
  const {
    loading: queryLoading,
    error: queryError,
    data: queryData
  } = useQuery(TRAVEL_QUERY, {
    // Get travel id from url
    variables: { id: iriPrefixes.travel + match.params.id }
  });

  // GQL Mutation: (edit travel (ID!))
  const [
    updateTravel,
    { loading: updateMutationLoading, error: updateMutationError }
  ] = useMutation(UPDATE_TRAVEL, {
    // Update cache
    refetchQueries: [
      {
        query: TRAVEL_QUERY,
        variables: {
          id: iriPrefixes.travel + match.params.id
        }
      }
    ],
    onCompleted() {
      // After mutation is done go to updated travel
      dispatch(push(`/travels/${match.params.id}`));
    }
  });

  // GQL Mutation: (delete travel (ID!))
  const [
    deleteTravel,
    { loading: deleteMutationLoading, error: deleteMutationError }
  ] = useMutation(DELETE_TRAVEL, {
    // Update cache
    refetchQueries: [
      {
        query: USER_TRAVELS_QUERY,
        variables: {
          id: iriPrefixes.user + userId
        }
      }
    ],
    // After mutation is done go to my travels page
    onCompleted() {
      dispatch(push('/travels/'));
    }
  });

  // If loading, show loading screen
  if (queryLoading || updateMutationLoading || deleteMutationLoading) {
    loader.show();
    return null;
  } else {
    loader.hide();
  }

  // If error hide loading screen and show error
  if (queryError || updateMutationError || deleteMutationLoading) {
    loader.hide();

    if (queryError) return `Error! ${queryError.message}`;
    if (updateMutationError) return `Error ${updateMutationError.message}`;
    if (deleteMutationError) return `Error ${deleteMutationError.message}`;
  }

  // Handling navigation action (delete travel)
  const handleNavigationHeaderAction = () => {
    deleteTravel({
      variables: {
        id: iriPrefixes.travel + match.params.id
      }
    });
  };

  // Handling submit of edit travel-form
  const handleFormSubmit = (id, title, description, costs) => {
    updateTravel({
      variables: {
        id: iriPrefixes.travel + match.params.id,
        title: title,
        description: description,
        costs: costs.length ? costs : null
      }
    });
  };

  // Render
  return (
    <TravelEdit
      navigation={{
        title: 'Reise',
        navigateTo: `/travels/${match.params.id}`,
        icon: 'arrow-left'
      }}
      action={{
        icon: 'trash',
        onClickFcn: handleNavigationHeaderAction
      }}
      mapData={{
        items: getWaypointItems(queryData.travel.waypoints, queryData.travel.id)
      }}
      id={queryData.travel.id}
      title={queryData.travel.title}
      description={queryData.travel.description}
      costs={
        queryData.travel.costs && queryData.travel.costs !== '0.00'
          ? queryData.travel.costs
          : ''
      }
      handleSubmit={handleFormSubmit}
      buttonText="Speichern"
    />
  );
};

export default TravelEditContainer;
