// Vendor imports
import React from 'react';
import { Marker, Popup } from 'react-leaflet';

// Custom imports
import Button from '../atoms/Button';
import LocateButton from '../molecules/LocateButton';
import LocationPopupContent from '../molecules/LocationPopupContent';
import Map from '../organisms/Map';
import {
  getLocationMapBounds,
  baseMarkerIcon,
  getMapCenterPosition,
  getMapZoomLevel
} from '../../../utilities/map';
import { buildIdUrl } from '../../../utilities/miscellaneous';
import { locationTypes } from '../../../constants/location';

const Locations = ({ locations, navigation }) => {
  return (
    <div className="locations">
      <main className="main locations__main">
        <div className="main__col">
          <Button
            to={navigation.navigateTo}
            additionalClassNames="locations__navigation-button"
          >
            {navigation.titel}
          </Button>
          <Map
            center={getMapCenterPosition(locations)}
            bounds={
              locations.length > 1 ? getLocationMapBounds(locations) : null
            }
            zoom={getMapZoomLevel(locations, 7, 5)}
            additionalClassNames="locations__map"
          >
            <LocateButton additionalClassNames="locations__locate-button" />

            {locations.length &&
              locations.map(location => {
                return (
                  <Marker
                    key={location.id}
                    position={[location.latitude, location.longitude]}
                    icon={baseMarkerIcon}
                  >
                    <Popup minWidth="240" maxWidth="240">
                      <LocationPopupContent
                        title={location.title}
                        type={location.type && locationTypes[location.type]}
                        subtitle={location.subtitle}
                        prices={location.prices}
                        url={buildIdUrl('locations/', location.id)}
                      />
                    </Popup>
                  </Marker>
                );
              })}
          </Map>
        </div>
      </main>
    </div>
  );
};

export default Locations;
