// Vendor imports
import React from 'react';
import classNames from 'classnames';
import { useDispatch } from 'react-redux';
import { push } from 'connected-react-router';

const Button = ({
  disabled,
  onClickFcn,
  type,
  styleType,
  fullWidth,
  to,
  additionalClassNames,
  children,
  isBlockElement
}) => {
  // State
  const dispatch = useDispatch();

  // Setup css classes
  const cssClasses = classNames(
    'button',
    {
      'button--block-element': isBlockElement,
      'button--full-width': fullWidth,
      'button--primary': !styleType,
      'button--secondary': styleType === 'secondary' ? 'secondary' : false,
      'button--no-styles': styleType === 'no-styles' ? 'no-styles' : false
    },
    additionalClassNames
  );

  return (
    <button
      type={type ? type : 'button'}
      disabled={disabled}
      onClick={event => {
        !type && onClickFcn && onClickFcn(event);
        !type && dispatch(push(to));
      }}
      className={cssClasses}
    >
      {children}
    </button>
  );
};

export default Button;
