// Vendor imports
import React, { useState } from 'react';
import { useQuery, useMutation } from '@apollo/react-hooks';
import { useDispatch, useSelector } from 'react-redux';
import { push } from 'connected-react-router';

// Custom imports
import LocationEdit from '../presentationals/templates/LocationEdit';
import { iriPrefixes } from '../../constants/gql';
import {
  TRAVEL_QUERY,
  WAYPOINT_QUERY,
  USER_TRAVELS_QUERY
} from '../../gql/queries';
import {
  DELETE_WAYPOINT,
  DELETE_WAYPOINT_AND_LOCATION,
  UPDATE_WAYPOINT_AND_LOCATION,
  UPLOAD_MEDIA_OBJECT,
  DELETE_MEDIA_OBJECT
} from '../../gql/mutations';
import {
  convertHtmlDateStringToMysqlDateTime,
  convertMysqlDateTimeToHtmlDateString
} from '../../utilities/date';
import { locationImageResizeOptions } from '../../constants/miscellaneous';
import { resizeImage } from '../../utilities/image';

// Prepare state mapping
const mapState = state => ({
  userId: state.user.id
});

// Component
const WaypointEditContainer = ({ match, loader }) => {
  // Get user id from redux store
  const { userId } = useSelector(mapState);

  // Local state definition
  const [formValues, setFormValues] = useState({
    arrivalDate: undefined,
    title: undefined,
    type: undefined,
    description: undefined,
    imageName: undefined,
    imageFile: undefined,
    services: undefined,
    geocoordinates: undefined,
    rating: undefined,
    prices: undefined
  });
  const [imageId, setImageId] = useState('');
  const [locationId, setLocationId] = useState('');
  const [waypointId, setWaypointId] = useState('');
  const [isFormDisabled, setIsFormDisabled] = useState(false);

  // Initialize hook to update redux store
  const dispatch = useDispatch();

  // GQL Query: (waypoint (ID!))
  const {
    loading: getWaypointLoading,
    error: getWaypointError,
    data: getWaypointData
  } = useQuery(WAYPOINT_QUERY, {
    variables: { id: iriPrefixes.waypoints + match.params.id },
    onCompleted() {
      // Update local state after query
      setFormValues({
        arrivalDate: convertMysqlDateTimeToHtmlDateString(
          getWaypointData.waypoint.arrivalDate
        ),
        title: getWaypointData.waypoint.location.title,
        type: getWaypointData.waypoint.location.type,
        services: getWaypointData.waypoint.location.services,
        description: getWaypointData.waypoint.location.description,
        imageName: getWaypointData.waypoint.location.image
          ? getWaypointData.waypoint.location.image.fileName
          : undefined,
        geocoordinates: `${getWaypointData.waypoint.location.latitude}, ${getWaypointData.waypoint.location.longitude}`,
        rating: getWaypointData.waypoint.location.rating,
        prices: getWaypointData.waypoint.location.prices
      });
      setWaypointId(getWaypointData.waypoint.id);
      setLocationId(getWaypointData.waypoint.location.id);
      setImageId(
        getWaypointData.waypoint.location.image
          ? getWaypointData.waypoint.location.image.id
          : undefined
      );
      // Disable form if the location is not owned by the current user
      if (
        getWaypointData.waypoint.location.user.id !==
        iriPrefixes.user + userId
      ) {
        setIsFormDisabled(true);
      }
    }
  });

  // GQL Mutation: (update waypoint and location (ID!, ID!))
  const [
    updateWaypointAndLocation,
    { loading: updateWaypointLoading, error: updateWaypointError }
  ] = useMutation(UPDATE_WAYPOINT_AND_LOCATION, {
    // Update cache
    refetchQueries: [
      {
        query: WAYPOINT_QUERY,
        variables: {
          id: iriPrefixes.waypoints + match.params.id
        }
      },
      {
        query: TRAVEL_QUERY,
        variables: {
          id: iriPrefixes.travel + match.params.travelId
        }
      }
    ]
  });

  // GQL Mutation: (delete travel (ID!))
  const [
    deleteWaypoint,
    { loading: deleteWaypointLoading, error: deleteWaypointError }
  ] = useMutation(DELETE_WAYPOINT, {
    // Update cache
    refetchQueries: [
      {
        query: USER_TRAVELS_QUERY,
        variables: {
          id: iriPrefixes.user + userId
        }
      }
    ]
  });

  // GQL Mutation: (delete waypoint and location (ID!))
  const [
    deleteWaypointAndConnectedLocation,
    {
      loading: deleteWaypointAndConnectedLocationLoading,
      error: deleteWaypointAndConnectedLocationError
    }
  ] = useMutation(DELETE_WAYPOINT_AND_LOCATION, {
    // Update cache
    refetchQueries: [
      {
        query: USER_TRAVELS_QUERY,
        variables: {
          id: iriPrefixes.user + userId
        }
      }
    ]
  });

  // GQL Mutation: (upload mediaObject (FILE!))
  const [
    uploadMediaObject,
    { loading: uploadMediaObjectLoading, error: uploadMediaObjectError }
  ] = useMutation(UPLOAD_MEDIA_OBJECT);

  // GQL Mutation: (delete mediaObject (ID!))
  const [
    deleteMediaObject,
    { loading: deleteMediaObjectLoading, error: deleteMediaObjectError }
  ] = useMutation(DELETE_MEDIA_OBJECT);

  // If loading, show loading screen
  if (
    getWaypointLoading ||
    updateWaypointLoading ||
    deleteWaypointLoading ||
    deleteWaypointAndConnectedLocationLoading ||
    uploadMediaObjectLoading ||
    deleteMediaObjectLoading
  ) {
    loader.show();
    return null;
  } else {
    loader.hide();
  }

  // If error hide loading screen and show error
  if (
    getWaypointError ||
    updateWaypointError ||
    deleteWaypointError ||
    deleteWaypointAndConnectedLocationError ||
    uploadMediaObjectError ||
    deleteMediaObjectError
  ) {
    loader.hide();

    if (getWaypointError) return `Error! ${getWaypointError.message}`;
    if (updateWaypointError) return `Error ${updateWaypointError.message}`;
    if (deleteWaypointError) return `Error ${deleteWaypointError.message}`;
    if (deleteWaypointAndConnectedLocationError)
      return `Error ${deleteWaypointAndConnectedLocationError.message}`;
    if (uploadMediaObjectError)
      return `Error ${uploadMediaObjectError.message}`;
    if (deleteMediaObjectError)
      return `Error ${deleteMediaObjectError.message}`;
  }

  // Delete waypoint. Delete connected location too, if
  // 1. location was created by current user
  // 2. location is not connected to any other waypoint
  const handleHeaderNavigationActionClick = async () => {
    if (
      iriPrefixes.user + userId === getWaypointData.waypoint.location.user.id &&
      getWaypointData.waypoint.location.numberOfWaypoints < 2
    ) {
      await deleteWaypointAndConnectedLocation({
        variables: {
          locationId: getWaypointData.waypoint.location.id,
          waypointId: getWaypointData.waypoint.id
        }
      });
    } else {
      await deleteWaypoint({
        variables: {
          id: iriPrefixes.waypoints + match.params.id
        }
      });
    }

    // After mutation is done go to my travels page
    dispatch(push(`/travels/${match.params.travelId}`));
  };

  // Update locale state on any changes of the location form
  const handleFormValueInputChange = value => {
    setFormValues(value);
  };

  // Initialise edit mutation chain
  const handleFormSubmit = async () => {
    const deleteCurrentImage =
      (imageId && formValues.imageFile) || (imageId && !formValues.imageName);
    let newMediaObject;
    let resizedFile;

    // If new image set, upload and await new mediaObject
    if (formValues.imageFile) {
      try {
        resizedFile = await resizeImage(
          formValues.imageFile,
          locationImageResizeOptions
        );
      } catch (event) {
        console.error(event.message);
      }

      resizedFile.name = formValues.imageName;

      try {
        newMediaObject = await uploadMediaObject({
          variables: {
            file: resizedFile
          }
        });
      } catch (event) {
        console.error(event.message);
      }
    }

    try {
      await updateWaypointAndLocation({
        variables: {
          waypointId: waypointId,
          arrivalDate:
            formValues.arrivalDate &&
            convertHtmlDateStringToMysqlDateTime(formValues.arrivalDate),
          locationId: locationId,
          title: formValues.title,
          type: formValues.type,
          services: formValues.services,
          description: formValues.description,
          image: newMediaObject
            ? newMediaObject.data.uploadMediaObject.mediaObject.id
            : newMediaObject,
          latitude: formValues.geocoordinates.split(',')[0].trim(),
          longitude: formValues.geocoordinates.split(',')[1].trim(),
          rating: Number(formValues.rating),
          prices: formValues.prices
        }
      });
    } catch (event) {
      console.error(event.message);
    }

    // If has changed, clean up and delete old mediaObject
    if (deleteCurrentImage) {
      try {
        // delete current mediaObject
        await deleteMediaObject({
          variables: {
            id: imageId
          }
        });
      } catch (event) {
        console.error(event.message);
      }
    }

    // After all mutations are done, go to updated waypoint
    dispatch(
      push(`/travels/${match.params.travelId}/waypoints/${match.params.id}`)
    );
  };

  return (
    <LocationEdit
      navigation={{
        title: 'Zwischenstop',
        navigateTo: `/travels/${match.params.travelId}/waypoints/${match.params.id}`,
        icon: 'arrow-left'
      }}
      action={{
        onClickFcn: handleHeaderNavigationActionClick,
        icon: 'trash'
      }}
      buttonText="Zwischenstop aktualisieren"
      isWaypoint={true}
      isFormDisabled={isFormDisabled}
      isgeocoordinatesFieldDisabled={true}
      formValues={formValues}
      handleFormSubmit={handleFormSubmit}
      handleFormValueInputChange={handleFormValueInputChange}
    />
  );
};

export default WaypointEditContainer;
