import L from 'leaflet';

/**
 * Custom Marker icon definition for a base marker
 *
 */

export const baseMarkerIcon = new L.Icon({
  iconUrl: require('../images/map-marker-green.svg'),
  iconRetinaUrl: require('../images/map-marker-green.svg'),
  iconAnchor: [13, 44],
  popupAnchor: [-5, -35],
  iconSize: [27, 44]
});

/**
 * Custom Marker icon definition for a my location marker
 *
 */

export const mylocationMarkerIcon = new L.Icon({
  iconUrl: require('../images/map-marker-red.svg'),
  iconRetinaUrl: require('../images/map-marker-red.svg'),
  iconAnchor: [13, 44],
  popupAnchor: [-5, -35],
  iconSize: [27, 44]
});

/**
 * Get map bounds from an array of location objects to fit map zoom level and centering
 *
 * @param {{latitude: float, longitude: float}[]} locations - An array with location objects.
 * @return {[float, float][]} - Leavelet latLngBounds Array (https://leafletjs.com/reference-1.5.0.html#latlngbounds)
 *
 */
export const getLocationMapBounds = locations => {
  const latlngbounds = new L.LatLngBounds();

  locations.forEach(location => {
    latlngbounds.extend([location.latitude, location.longitude]);
  });

  return latlngbounds;
};

/**
 * Get map bounds from an array of waypoint objects to fit map zoom level and centering
 *
 * @param {{latitude: float, longitude: float}[]} waypoints - An array with waypoint objects.
 * @return {[float, float][]} - Leavelet latLngBounds object (https://leafletjs.com/reference-1.5.0.html#latlngbounds)
 *
 */
export const getWaypointMapBounds = waypoints => {
  const latlngbounds = new L.LatLngBounds();

  waypoints.forEach(waypoint => {
    latlngbounds.extend([
      waypoint.location.latitude,
      waypoint.location.longitude
    ]);
  });

  return latlngbounds;
};

/**
 * Get center coordinates for leaflet map
 * 1. If there isn't any item to display, center to europe (fallback)
 * 2. If there exactly one item to display, take coordinates of this item
 * 3. If there're more items to display, set center prop to null in order to
 *    use bounds property
 *
 * @param {{latitude: float, longitude: float}[]} items - An array of locations objects locations, which needs
 *   to have at least a property latitude and a property longitude
 * @return {[float, float]} - Leavelet LatLng array [lat,lng] (https://leafletjs.com/reference-1.5.0.html#latlng)
 *
 */
export const getMapCenterPosition = items => {
  const geoCoordinatesArrayOfEurope = [48.127438, 4.1421032];
  let center = null;

  if (items.length) {
    if (items.length === 1) {
      center = [items[0].latitude, items[0].longitude];
    }
  } else {
    center = geoCoordinatesArrayOfEurope;
  }

  return center;
};

/**
 * Get zoom level for leaflet map
 * 1. If there isn't any item to display, set zoom level to 5
 *    (in order to see i.e. whole europe)
 * 2. If there exactly one item to display, set zoom level to
 *    zoomLevelForOnlyOneItem (f.e. in order to have a proper map bounding)
 * 3. If there're more items to display, set center prop to null in order to
 *    use bounds property
 *
 * @param {{latitude: float, longitude: float}[]} items - An array of locations objects
 * @param {int} zoomLevelForOnlyOneItem - leaflet map zoomLevel
 * @param {int} fallBackZoomLevel - leaflet map zoomLevel
 * @return {int} - leaflet map zoomLevel
 *
 */
export const getMapZoomLevel = (
  items,
  zoomLevelForOnlyOneItem,
  fallBackZoomLevel
) => {
  let zoomLevel = fallBackZoomLevel;

  if (items.length === 1) {
    zoomLevel = zoomLevelForOnlyOneItem;
  }

  return zoomLevel;
};
