// Vendor imports
import React from 'react';
import classNames from 'classnames';

// Custom imports
import Label from '../atoms/Label';

// Component
const SelectField = ({
  label,
  name,
  placeholder,
  value,
  disabled,
  required,
  options,
  onChangeFcn,
  additionalClassNames
}) => {
  // Setup css classes
  const cssClasses = classNames('select-field', additionalClassNames);

  const renderLabel = () => {
    return (
      label && (
        <Label htmlFor={name} className="select-field__label" text={label} />
      )
    );
  };
  return (
    <div className={cssClasses}>
      {renderLabel()}


      <div className="select-field__select-wrapper">
        <select
          id={name}
          name={name}
          value={value}
          onChange={event => onChangeFcn(event.target.value)}
          disabled={disabled}
          required={required}
          className="select-field__select"
          size="1"
        >
          <option disabled>{placeholder}</option>
          {Object.keys(options).map(key => {
            return (
              <option key={key} value={key}>
                {options[key]}
              </option>
            );
          })}
        </select>
      </div>
    </div>
  );
};

export default SelectField;
