// vendor imports
import { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { push } from 'connected-react-router';

// custom imports
import {
  getToken,
  getTokenData,
  isTokenExpired,
  resetToken
} from '../utilities/authentication';
import { isTimestampInThePast } from '../utilities/date';
import {
  IS_AUTHENTICATED,
  IS_AUTHENTICATION_EXPIRED,
  IS_UNAUTHENTICATED
} from '../actions/authActions';

const mapState = state => ({
  authenticated: state.user.authenticated,
  exp: state.user.exp
});

// Custom hook to check authorisation state
// if unauthorized, then go to login page
export const useAuthenticationGuard = () => {
  // State
  const dispatch = useDispatch();
  const { authenticated, exp } = useSelector(mapState);

  // Use sideEffect to hook into livecycle methods of a component
  useEffect(() => {
    // if state authenticated is true
    if (authenticated) {
      // if state exp is in the past
      if (isTimestampInThePast(exp)) {
        // Dispatch IS_AUTHENTICATION_EXPIRED and go to login page
        dispatch({ type: IS_AUTHENTICATION_EXPIRED });
        dispatch(push('/login'));
      }
    }
    // if state authenticated is false
    else {
      // get token
      const token = getToken();

      // if there's a token
      if (token) {
        // token is expired
        if (isTokenExpired(token)) {
          // Dispatch IS_AUTHENTICATION_EXPIRED and go to login page
          dispatch({ type: IS_AUTHENTICATION_EXPIRED });
          resetToken();
          dispatch(push('/login'));
        }
        // token is valid
        else {
          // get token data
          const tokenData = getTokenData(token);

          // Dispatch IS_AUTHENTICATED
          dispatch({
            type: IS_AUTHENTICATED,
            ...tokenData
          });
        }
      }
      // There's no token
      else {
        // Set IS_UNAUTHENTICATED state and go to login page
        dispatch({ type: IS_UNAUTHENTICATED });
        dispatch(push('/login'));
      }
    }
  }, [authenticated, exp, dispatch]);
  return authenticated;
};
