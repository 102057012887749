/**
 * Convert a html date string (i.e. given by input[type=date]) to a mysql
 * datetime in order to save it in a database
 *
 * @param {string} htmlDateString - see: https://developer.mozilla.org/en-US/docs/Web/HTML/Date_and_time_formats#Format_of_a_valid_date_string
 * @return {String} Mysql DateTime
 */
export const convertHtmlDateStringToMysqlDateTime = htmlDateString => {
  return new Date(htmlDateString)
    .toISOString()
    .slice(0, 19)
    .replace('T', ' ');
};

/**
 * Convert a mysql datetime (i.e. requested from a database field) to a html
 * date string in order to visualize in a input[type=date] field of a html
 * document
 *
 * @param {string} mysqlDatetime - see: https://dev.mysql.com/doc/refman/5.7/en/datetime.html
 * @return {String} Html date string
 */
export const convertMysqlDateTimeToHtmlDateString = mysqlDatetime => {
  return mysqlDatetime.slice(0, 10);
};

/**
 * Convert a mysql datetime (i.e. requested from a database field) to a german
 * formatted date string
 *
 * @param {string} mysqlDatetime - see: https://dev.mysql.com/doc/refman/5.7/en/datetime.html
 * @return {String} Date string (dd.mmm.YYYY)
 */
export const getFormatedDateFromMysqlDatetime = mysqlDatetime => {
  const jsDate = new Date(Date.parse(mysqlDatetime));
  return getFormatedDateFromJsDate(jsDate);
};

/**
 * Convert a javascript date object to a printable german formatted date string
 *
 * @param {date} jsDate - see: https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Date
 * @return {String} Date string (dd.mmm.YYYY)
 */
export const getFormatedDateFromJsDate = jsDate => {
  const format = { day: '2-digit', month: '2-digit', year: 'numeric' };
  return jsDate.toLocaleDateString('de-DE', format);
};

/**
 * Check if a given unix timestamp is in the past
 *
 * @param {date} timestamp - Unix timestamp
 * @return {boolean}
 */
export const isTimestampInThePast = timestamp => {
  try {
    return timestamp < Date.now() / 1000;
  } catch (error) {
    return false;
  }
};

/**
 * Sort an array of dates
 *
 * @param {[date]} dateArray - Array of dates
 * @param {boolean} isAscending - Sort ascending or descending
 * @return {[date]} array with sorted dates
 */
export const sortDateArray = (dateArray, isAscending) => {
  const sortedDateArray = dateArray.sort(function(date1, date2) {
    return new Date(date1) - new Date(date2);
  });
  return isAscending ? sortedDateArray : sortedDateArray.reverse();
};

/**
 * Get start- or end-date of a travel
 *
 * @param {{arrivalDate: string}[]} waypoints - Array of waypoint objects
 * @param {boolean} isEndDate - get end-date instead of start-date
 * @return {String} Date string (dd.mmm.YYYY)
 */
export const getTravelDate = (waypoints, isEndDate) => {
  const date = isEndDate
    ? waypoints[waypoints.length - 1].arrivalDate
    : waypoints[0].arrivalDate;
  return getFormatedDateFromMysqlDatetime(date);
};
