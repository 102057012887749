import { getFormatedDateFromMysqlDatetime } from './date';
import { buildIdUrl } from './miscellaneous';

/**
 * Prepare waypoint item data to be rendered in ItemList and Map components
 *
 * @param {{id: string, location: {title: string, latitude: string, longitude: string, type: string, prices: string}}[]} waypoints - Array of waypoint objects
 * @param {string} travelId - Id of the corresponding
 * @return {[string, string, string, string, string, string, string, string]} array with proper prepared waypoint data
 *
 */
export const getWaypointItems = (waypoints, travelId) => {
  const waypointItems = [];

  waypoints.forEach(waypoint => {
    waypointItems.push({
      id: waypoint.id,
      title: waypoint.location.title,
      subtitle: getFormatedDateFromMysqlDatetime(waypoint.arrivalDate),
      latitude: waypoint.location.latitude,
      longitude: waypoint.location.longitude,
      type: waypoint.location.type,
      prices: waypoint.location.prices,
      url: `${buildIdUrl('/travels/', travelId)}/${buildIdUrl(
        'waypoints/',
        waypoint.id
      )}`
    });
  });

  return waypointItems;
};
