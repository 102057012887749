// Vendor imports
import React from 'react';
import classNames from 'classnames';

// Custom imports
import Icon from '../atoms/Icon';
import Button from '../atoms/Button';

// Component
const ItemListHeader = ({ title, action, additionalClassNames }) => {
  // Setup css classes
  const cssClasses = classNames('item-list-header', additionalClassNames);

  const renderAction = () => {
    return (
      action && (
        <Button
          to={action.navigateTo}
          onClickFcn={action.onClickFcn}
          styleType="no-styles"
          additionalClassNames="item-list-header__action-button"
        >
          <Icon
            icon={action.icon}
            additionalClassNames="item-list-header__action-button-icon"
            circle={true}
          />
          {action.title}
        </Button>
      )
    );
  };

  return (
    <header className={cssClasses}>
      <h2 className="item-list-header__title">{title}</h2>
      {renderAction()}
    </header>
  );
};

export default ItemListHeader;
