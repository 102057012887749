// Vendor imports
import React from 'react';
import { push } from 'connected-react-router';
import { useMutation } from '@apollo/react-hooks';
import { useDispatch, useSelector } from 'react-redux';

// Custom imports
import TravelEdit from '../presentationals/templates/TravelEdit';
import { CREATE_TRAVEL } from '../../gql/mutations';
import { iriPrefixes } from '../../constants/gql';
import { buildIdUrl } from '../../utilities/miscellaneous';
import { USER_TRAVELS_QUERY } from '../../gql/queries';

// Prepare state mapping
const mapState = state => ({
  userId: state.user.id
});

// Component
const TravelCreateContainer = ({ loader }) => {
  // Get user id from redux store
  const { userId } = useSelector(mapState);

  // Initialize hook to update redux store
  const dispatch = useDispatch();

  // GQL Mutation: (create travel)
  const [createTravel, { loading, error }] = useMutation(CREATE_TRAVEL, {
    // Update cache
    refetchQueries: [
      {
        query: USER_TRAVELS_QUERY,
        variables: {
          id: iriPrefixes.user + userId
        }
      }
    ],
    // After mutation is done go to new travel page
    onCompleted({ createTravel }) {
      dispatch(push(buildIdUrl('/travels/', createTravel.travel.id)));
    }
  });

  // handling submit of create travel-form
  const handleFormSubmit = (id, title, description, costs) => {
    createTravel({
      variables: {
        user: iriPrefixes.user + userId,
        title: title,
        description: description.length ? description : null,
        costs: costs.length ? costs : null
      }
    });
  };

  // If loading, show loading screen
  if (loading) {
    loader.show();
    return null;
  } else {
    loader.hide();
  }

  // If error hide loading screen and show error
  if (error) {
    loader.hide();
    return `Error! ${error.message}`;
  }

  // Render
  return (
    <TravelEdit
      navigation={{
        title: 'Meine Reisen',
        navigateTo: '/travels',
        icon: 'arrow-left'
      }}
      mapData={{
        items: {}
      }}
      handleSubmit={handleFormSubmit}
      buttonText="Reise hinzufügen"
    />
  );
};

export default TravelCreateContainer;
