// Vendor imports
import React from 'react';
import classNames from 'classnames';

// Custom imports
import Button from '../atoms/Button';
import InputField from '../molecules/InputField';
import SelectField from '../molecules/SelectField';
import TextareaField from '../molecules/TextareaField';
import ButtonWithInputField from '../molecules/ButtonWithInputField';
import UploadField from '../molecules/UploadField';
import { locationTypes, services } from '../../../constants/location';
import { requestGeolocation } from '../../../utilities/geolocation';
import TagListField from '../molecules/TagListField';

// Component
const LocationForm = ({
  additionalClassNames,
  isWaypoint,
  isgeocoordinatesFieldDisabled,
  isFormDisabled,
  formValues,
  buttonText,
  handleFormValueInputChange,
  handleFormSubmit
}) => {
  // Setup css classes
  const cssClasses = classNames('form', additionalClassNames);

  const handleLocateButtonClick = () => {
    requestGeolocation(geocoordinates => {
      handleFormValueInputChange({
        ...formValues,
        geocoordinates: `${geocoordinates.coords.latitude},${geocoordinates.coords.longitude}`
      });
    });
  };

  const renderArrivalDateInput = () => {
    return (
      isWaypoint && (
        <div className="form__field-group">
          <InputField
            label="Anreise Datum"
            onChangeFcn={value =>
              handleFormValueInputChange({ ...formValues, arrivalDate: value })
            }
            name="arrivalDate"
            type="date"
            placeholder="Wann hast Du diesen Zwischenstop besucht?"
            value={formValues.arrivalDate}
            required={true}
            additionalClassNames="form__input-field"
          />
        </div>
      )
    );
  };

  // Render
  return (
    <div className={cssClasses}>
      <form
        className="form location-form__form"
        onSubmit={event => {
          event.preventDefault();
          handleFormSubmit(event);
        }}
        method="POST"
        encType="multipart/form-data"
      >
        <div className="form__field-group">
          {renderArrivalDateInput()}

          <div className="form__field-group">
            <InputField
              label="Titel"
              onChangeFcn={value =>
                handleFormValueInputChange({ ...formValues, title: value })
              }
              name="title"
              type="text"
              placeholder="Gib dem Zwischenstop einen Titel"
              value={formValues.title}
              required={true}
              additionalClassNames="form__input-field"
              disabled={isFormDisabled}
            />
          </div>

          <ButtonWithInputField
            label="Geokoordinaten (Lat/Lng)"
            onChangeFcn={value =>
              handleFormValueInputChange({
                ...formValues,
                geocoordinates: value
              })
            }
            name="geocoordinates"
            placeholder="Latitude, Longitude"
            value={formValues.geocoordinates}
            type="text"
            required={true}
            buttonOnClickFcn={handleLocateButtonClick}
            buttonStyleType="secondary"
            buttonIcon="compass"
            additionalClassNames="form__geocoordinates-input-field"
            disabled={isgeocoordinatesFieldDisabled}
          />
        </div>
        <div className="form__field-group">
          <SelectField
            label="Typ"
            onChangeFcn={value =>
              handleFormValueInputChange({ ...formValues, type: value })
            }
            name="type"
            placeholder="Was ist das für ein Ort?"
            value={formValues.type}
            required={true}
            disabled={isFormDisabled}
            additionalClassNames="form__select-field"
            options={locationTypes}
          />
        </div>
        <div className="form__field-group">
          <UploadField
            label="Bild (jpg oder png)"
            onChangeFcn={file =>
              handleFormValueInputChange({
                ...formValues,
                imageFile: file,
                imageName: file ? file.name : undefined
              })
            }
            name="image"
            placeholder="Kein Bild ausgewählt"
            fileName={formValues.imageName ? formValues.imageName : undefined}
            accept="image/*"
            required={false}
            buttonStyleType="secondary"
            buttonIcon="plus"
            additionalClassNames="form__upload-input-field"
            disabled={isFormDisabled}
          />
        </div>
        <div className="form__field-group">
          <TagListField
            label="Services"
            onChangeFcn={value =>
              handleFormValueInputChange({ ...formValues, services: value })
            }
            name="services"
            value={formValues.services}
            required={false}
            disabled={isFormDisabled}
            tagFields={services}
            additionalClassNames="form__tag-list-field"
          />
        </div>
        <div className="form__field-group">
          <InputField
            label="Kosten"
            onChangeFcn={value =>
              handleFormValueInputChange({ ...formValues, prices: value })
            }
            name="prices"
            type="text"
            placeholder="Sind kosten angefallen?"
            value={formValues.prices}
            additionalClassNames="form__input-field"
            disabled={isFormDisabled}
            required={false}
          />
        </div>
        <div className="form__field-group">
          <InputField
            label="Bewertung (1 - 5)"
            onChangeFcn={value =>
              handleFormValueInputChange({ ...formValues, rating: value })
            }
            name="rating"
            type="number"
            min="1"
            max="5"
            step="1"
            placeholder="Wieviel Sterne vergeben Sie?"
            value={formValues.rating}
            required={true}
            additionalClassNames="form__input-field"
            disabled={isFormDisabled}
          />
        </div>
        <div className="form__field-group">
          <TextareaField
            label="Beschreibung"
            onChangeFcn={value =>
              handleFormValueInputChange({ ...formValues, description: value })
            }
            name="description"
            rows="10"
            placeholder="Beschreibe die Reise"
            value={formValues.description}
            additionalClassNames="form__input-field"
            required={true}
            disabled={isFormDisabled}
          />
        </div>
        <div className="form__field-group">
          <Button
            type="submit"
            additionalClassNames="form__button"
            fullWidth={true}
          >
            {buttonText}
          </Button>
        </div>
      </form>
    </div>
  );
};

export default LocationForm;
