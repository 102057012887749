// Vendor imports
import React, { createRef, useState, useEffect } from 'react';
import classNames from 'classnames';

// Custom imports
import Label from '../atoms/Label';
import Button from '../atoms/Button';
import Icon from '../atoms/Icon';

// Component
const UploadField = ({
  label,
  name,
  placeholder,
  fileName,
  disabled,
  required,
  accept,
  onChangeFcn,
  buttonStyleType,
  additionalClassNames
}) => {
  // State
  const [hasFile, setHasFile] = useState(fileName);

  // Update state
  useEffect(() => setHasFile(fileName), [fileName]);

  // References
  const fileInput = createRef();
  const hasFileInput = createRef();

  // Setup css classes
  const cssClasses = classNames(
    'upload-field',
    {
      'upload-field--filled': hasFile
    },
    additionalClassNames
  );

  // Clear file on click of trash btn
  const clearFile = () => {
    fileInput.current.value = '';
    hasFileInput.current.value = '';
    setHasFile(false);
    onChangeFcn(undefined);
  };

  // Add or change file
  const onFileChange = () => {
    const file = fileInput.current.files[0];
    hasFileInput.current.value = file.name;
    setHasFile(true);
    onChangeFcn(file);
  };

  const renderLabel = () => {
    return (
      label && (
        <Label
          htmlFor={name}
          additionalClassNames="upload-field__label"
          text={label}
        />
      )
    );
  };

  return (
    <div className={cssClasses}>
      {renderLabel()}
      <div className="upload-field__wrapper">
        <input
          id={name}
          name={name}
          type="file"
          accept={accept}
          onChange={event => onFileChange(event)}
          disabled={disabled}
          required={required}
          ref={fileInput}
          className="input-field__input upload-field__file-input"
        />
        <input
          id={name + '-display'}
          name={name + '-display'}
          type="text"
          placeholder={placeholder}
          defaultValue={fileName}
          disabled={true}
          required={required}
          ref={hasFileInput}
          className="input-field__input upload-field__display-input"
        />

        <label
          htmlFor={name}
          className="button button--secondary upload-field__add-button"
          disabled={disabled}
        >
          <Icon icon="plus" />
        </label>

        <Button
          onClickFcn={clearFile}
          styleType={buttonStyleType}
          additionalClassNames="upload-field__clear-button"
          disabled={disabled}
        >
          <Icon icon="trash" />
        </Button>
      </div>
    </div>
  );
};

export default UploadField;
