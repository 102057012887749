// Vendor imports
import React from 'react';
import classNames from 'classnames';

// Cutom imports
import ItemListHeader from '../molecules/ItemListHeader';
import ItemListContent from '../molecules/ItemListContent';

// Component
const ItemList = ({ header, items, additionalClassNames }) => {
  // Setup css classes
  const cssClasses = classNames('item-list', additionalClassNames);

  const renderHeader = () => {
    return (
      header && <ItemListHeader title={header.title} action={header.action} />
    );
  };

  const renderList = () => {
    if (items.length) {
      return (
        <ul className="item-list__list">
          {items.map(item => (
            <li className="item-list__element" key={item.id}>
              <ItemListContent
                title={item.title}
                subtitle={item.subtitle}
                url={item.url}
              />
            </li>
          ))}
        </ul>
      );
    }
  };

  return (
    <section className={cssClasses}>
      {renderHeader()}
      {renderList()}
    </section>
  );
};

export default ItemList;
