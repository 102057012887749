// Vendor imports
import React from 'react';
import { useSelector } from 'react-redux';

// Custom imports
import StatusMessage from '../atoms/StatusMessage';
import LoginForm from '../organisms/LoginForm';
import statusMessages from '../../../constants/statusMessages';

const mapState = state => ({
  authenticated: state.user.authenticated,
  statusMessage: state.user.authErrorMessage
});

const Login = ({ statusMessage, handleSubmit }) => {
  // State
  const { authenticated } = useSelector(mapState);

  // If already authenticated, set corresponding status message
  if (authenticated) {
    statusMessage = statusMessages.alreadyLoggedIn;
  }

  // Component
  return (
    <div className="login">
      <StatusMessage
        message={statusMessage}
        status="error"
        additionalClassNames="login__status-message"
      />
      <main className="main login__main">
        <LoginForm
          handleSubmit={handleSubmit}
          authenticated={authenticated}
          additionalClassNames="login__login-form"
        />
      </main>
    </div>
  );
};

export default Login;
