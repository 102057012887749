// Vendor imports
import { MapLayer } from 'react-leaflet';
import L from 'leaflet';
import 'leaflet-routing-machine';
import '../../../utilities/L.Routing.OpenRouteService';
import { withLeaflet } from 'react-leaflet';
import { difference } from 'lodash';

// Custom imports
import config from '../../../constants/config';

class Routing extends MapLayer {
  createLeafletElement(props) {
    const { leaflet, items, color } = props;
    // Get leaflet map object from context
    const map = leaflet.map;

    // Setup array with LatLng arrays bases on waypoint items
    const waypoints = [];
    items.length &&
      items.forEach(waypoint => {
        waypoints.push([waypoint.latitude, waypoint.longitude]);
      });

    // Setup route
    let leafletElement = L.Routing.control({
      router: new L.Routing.openrouteservice(config.openRouteService.apiKey),
      waypoints: waypoints,
      fitSelectedRoutes: false,
      useZoomParameter: false,
      lineOptions: {
        styles: [
          {
            color: 'black',
            opacity: 0.15,
            weight: 10
          },
          {
            color: 'white',
            opacity: 0.8,
            weight: 7
          },
          {
            color: color,
            opacity: 1,
            weight: 3
          }
        ],
        addWaypoints: false
      },
      // Get rid of markers
      createMarker: function() {
        return null;
      }
    }).addTo(map);
    leafletElement.hide();

    return leafletElement.getPlan();
  }

  updateLeafletElement(fromProps, toProps) {
    if (difference(fromProps.items, toProps.items)) {
      // Setup array with LatLng arrays bases on waypoint items
      const waypoints = [];
      toProps.items.length &&
        toProps.items.forEach(waypoint => {
          waypoints.push([waypoint.latitude, waypoint.longitude]);
        });

      // Set new route
      this.leafletElement.setWaypoints(waypoints);
    }
  }

  componentDidUpdate(fromProps) {
    this.updateLeafletElement(fromProps, this.props);
  }
}

export default withLeaflet(Routing);
