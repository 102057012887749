// Vendor imports
import React from 'react';
import { Marker } from 'react-leaflet';
import { Breakpoint } from 'react-socks';

// custom imports
import Map from '../organisms/Map';
import NavigationHeader from '../molecules/NavigationHeader';
import LocationForm from '../organisms/LocationForm';
import { baseMarkerIcon } from '../../../utilities/map';
import { testGeocoordinatesFormat } from '../../../utilities/geolocation';

const LocationEdit = ({
  navigation,
  action,
  isWaypoint,
  isFormDisabled,
  isgeocoordinatesFieldDisabled,
  buttonText,
  formValues,
  handleFormValueInputChange,
  handleFormSubmit,
  onClickOfMapFcn
}) => {
  const renderMarker = () => {
    return (
      testGeocoordinatesFormat(formValues.geocoordinates) && (
        <Marker
          position={[
            formValues.geocoordinates.split(',')[0].trim(),
            formValues.geocoordinates.split(',')[1].trim()
          ]}
          icon={baseMarkerIcon}
        />
      )
    );
  };

  return (
    <div className="location-edit">
      <main className="main">
        <div className="main__col main__col--left">
          <NavigationHeader navigation={navigation} action={action} />
          <LocationForm
            isWaypoint={isWaypoint}
            isFormDisabled={isFormDisabled}
            isgeocoordinatesFieldDisabled={isgeocoordinatesFieldDisabled}
            additionalClassNames="location-edit__location-form"
            buttonText={buttonText}
            formValues={formValues}
            handleFormValueInputChange={handleFormValueInputChange}
            handleFormSubmit={handleFormSubmit}
          />
        </div>
        <Breakpoint medium up className="main__col main__col--right">
          <Map
            center={
              testGeocoordinatesFormat(formValues.geocoordinates)
                ? [
                    formValues.geocoordinates.split(',')[0],
                    formValues.geocoordinates.split(',')[1]
                  ]
                : [48.127438, 4.1421032]
            }
            zoom={
              testGeocoordinatesFormat(formValues.geocoordinates) ? '12' : '5'
            }
            onClickFcn={onClickOfMapFcn}
            additionalClassNames="location-edit__map"
          >
            {renderMarker()}
          </Map>
        </Breakpoint>
      </main>
    </div>
  );
};

export default LocationEdit;
