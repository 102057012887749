// Vendor imports
import React from 'react';
import classNames from 'classnames';

// Custom imports
import Button from '../atoms/Button';

// Component
const LocationPopupContent = ({
  title,
  type,
  subtitle,
  prices,
  url,
  additionalClassNames
}) => {
  // Setup css classes
  const cssClasses = classNames('location-popup-content', additionalClassNames);

  return (
    <section className={cssClasses}>
      <header className="location-popup-content__header">
        <h3 className="location-popup-content__title">{title}</h3>
        <small className="location-popup-content__subtitle">{subtitle}</small>
      </header>
      <div className="location-popup-content__body">
        <ul className="location-popup-content__data-list">
          <li className="location-popup-content__data-list-item">
            Typ: {type}
          </li>
          <li className="location-popup-content__data-list-item">
            Kosten: {prices}
          </li>
        </ul>
        <Button
          to={url}
          fullWidth={true}
          additionalClassNames="location-popup-content__button"
        >
          Ort ansehen
        </Button>
      </div>
    </section>
  );
};

export default LocationPopupContent;
