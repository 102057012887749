// Vendor imports
import React from 'react';
import { Marker, Popup } from 'react-leaflet';
import { Breakpoint } from 'react-socks';

// custom imports
import Map from '../organisms/Map';
import NavigationHeader from '../molecules/NavigationHeader';
import LocationPopupContent from '../molecules/LocationPopupContent';
import TravelForm from '../organisms/TravelForm';
import {
  baseMarkerIcon,
  getLocationMapBounds,
  getMapCenterPosition,
  getMapZoomLevel
} from '../../../utilities/map';
import { locationTypes } from '../../../constants/location';

const TravelEdit = ({
  id,
  navigation,
  action,
  title,
  description,
  costs,
  mapData,
  handleSubmit,
  buttonText
}) => {
  return (
    <div className="travel-edit">
      <main className="main">
        <div className="main__col main__col--left">
          <NavigationHeader navigation={navigation} action={action} />
          <TravelForm
            id={id}
            initialTitle={title}
            initialDescription={description}
            initialCosts={costs}
            handleSubmit={handleSubmit}
            buttonText={buttonText}
            additionalClassNames="travel-edit__travel-form"
          />
        </div>
        <Breakpoint medium up className="main__col main__col--right">
          <Map
            center={getMapCenterPosition(mapData.items)}
            bounds={
              mapData.items.length > 1
                ? getLocationMapBounds(mapData.items)
                : null
            }
            zoom={getMapZoomLevel(mapData.items, 7, 5)}
            additionalClassNames="travel-edit__map"
          >
            {mapData &&
              mapData.items.length &&
              mapData.items.map(waypoint => {
                return (
                  <Marker
                    key={waypoint.id}
                    position={[waypoint.latitude, waypoint.longitude]}
                    icon={baseMarkerIcon}
                  >
                    <Popup minWidth="240" maxWidth="240">
                      <LocationPopupContent
                        title={waypoint.title}
                        type={waypoint.type && locationTypes[waypoint.type]}
                        subtitle={waypoint.subtitle}
                        prices={waypoint.prices}
                        url={waypoint.url}
                      />
                    </Popup>
                  </Marker>
                );
              })}
          </Map>
        </Breakpoint>
      </main>
    </div>
  );
};

export default TravelEdit;
