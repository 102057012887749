// Vendor imports
import React from 'react';
import { Provider as ReduxProvider } from 'react-redux';
import { ApolloProvider } from '@apollo/react-hooks';
import { Route, Switch } from 'react-router';
import { ConnectedRouter } from 'connected-react-router';
import { BreakpointProvider, setDefaultBreakpoints } from 'react-socks';

// Custom imports
import configureStore, { history } from '../store';
import gqlClient from '../gql/client';
import AuthenticationGuard from './container/AuthenticationGuard.js';
import LoginContainer from './container/LoginContainer';
import TravelsContainer from './container/TravelsContainer';
import TravelContainer from './container/TravelContainer';
import TravelCreateContainer from './container/TravelCreateContainer';
import LocationsContainer from './container/LocationsContainer';
import LocationContainer from './container/LocationContainer';
import WaypointContainer from './container/WaypointContainer';
import WaypointEditContainer from './container/WaypointEditContainer';
import WaypointCreateContainer from './container/WaypointCreateContainer';
import NotFoundContainer from './container/NotFoundContainer';
import TravelEditContainer from './container/TravelEditContainer';

// Setup store
const initialState = {
  user: {
    authenticated: false,
    authErrorMessage: null,
    exp: null,
    iat: null,
    roles: [],
    username: null,
    id: null
  }
};

const store = configureStore(initialState);

// Setup Breakpoints
setDefaultBreakpoints([{ medium: 768 }]);

const loader = {
  loaderElement: document.querySelector('.loader'),
  show: () => loader.loaderElement.classList.remove('loader--hide'),
  hide: () => loader.loaderElement.classList.add('loader--hide')
};

const App = () => {
  return (
    <ReduxProvider store={store}>
      <ApolloProvider client={gqlClient}>
        <BreakpointProvider>
          <ConnectedRouter history={history}>
            <Switch>
              <Route
                exact
                path="/login"
                render={props => <LoginContainer {...props} loader={loader} />}
              />
              <AuthenticationGuard>
                <Route
                  exact
                  path="/travels/:travelId/waypoints/:id/edit"
                  render={props => (
                    <WaypointEditContainer {...props} loader={loader} />
                  )}
                />
                <Route
                  exact
                  path="/travels/:travelId/waypoints/:id"
                  render={props => (
                    <WaypointContainer {...props} loader={loader} />
                  )}
                />
                <Route
                  exact
                  path="/travels/:id/add-waypoint"
                  render={props => (
                    <WaypointCreateContainer {...props} loader={loader} />
                  )}
                />
                <Route
                  exact
                  path="/travels/:id/edit"
                  render={props => (
                    <TravelEditContainer {...props} loader={loader} />
                  )}
                />
                <Route
                  exact
                  path="/create-travel"
                  render={props => (
                    <TravelCreateContainer {...props} loader={loader} />
                  )}
                />
                <Route
                  exact
                  path="/travels/:id"
                  render={props => (
                    <TravelContainer {...props} loader={loader} />
                  )}
                />
                <Route
                  exact
                  path="/travels"
                  render={props => (
                    <TravelsContainer {...props} loader={loader} />
                  )}
                />
                <Route
                  exact
                  path="/locations/:id"
                  render={props => (
                    <LocationContainer {...props} loader={loader} />
                  )}
                />
                <Route
                  exact
                  path="/"
                  render={props => (
                    <LocationsContainer {...props} loader={loader} />
                  )}
                />
              </AuthenticationGuard>
              <Route component={NotFoundContainer} />
            </Switch>
          </ConnectedRouter>
        </BreakpointProvider>
      </ApolloProvider>
    </ReduxProvider>
  );
};

export default App;
