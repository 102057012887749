// Vendor imports
import decode from 'jwt-decode';

// Custom imports
import config from '../constants/config';
import { isTimestampInThePast } from './date';

/**
 * Send an auth request in order to login
 *
 * @param {string} email
 * @param {string} password
 * @return {function(email, password)} - Return fetch function
 *
 */

export const login = (email, password) => {
  return (
    fetch(config.authEndpoint, {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({
        email,
        password
      })
    })
      // Error handling:
      // - Throws an error in case response status is not a success
      // - Success status lies between 200 to 300
      .then(response => {
        if (response.status >= 200 && response.status < 300) {
          return response;
        } else {
          const error = new Error(response.statusText);
          error.response = response;
          throw error;
        }
      })
      // Parse response
      .then(response => response.json())
      // Safe the token in localStorage
      .then(response => {
        setToken(response.token);
        return Promise.resolve(response);
      })
  );
};

/**
 * Checks if token is expired
 *
 * @param {string} token
 * @return {boolean} - Return fetch functionb
 *
 */

export const isTokenExpired = token => {
  try {
    const decoded = decode(token);
    return isTimestampInThePast(decoded.exp);
  } catch (err) {
    return false;
  }
};

/**
 * Saves auth token to localStorage
 *
 * @param {string} token
 *
 */
export const setToken = token => {
  localStorage.setItem('token', token);
};

/**
 * Retrieves the auth token from localStorage
 *
 * @return {string} token
 *
 */
export const getToken = () => {
  return localStorage.getItem('token');
};

/**
 * Clear auth token from localStorage
 *
 */
export const resetToken = () => {
  localStorage.removeItem('token');
};

/**
 * Using jwt-decode npm package to decode the token
 *
 * @param {string} token
 * @return {{}} token object
 */
export const getTokenData = token => {
  return decode(token);
};
