// Vendor imports
import React, { useState } from 'react';
import { useMutation } from '@apollo/react-hooks';

// Custom imports
import LocationEdit from '../presentationals/templates/LocationEdit';
import { iriPrefixes } from '../../constants/gql';
import { convertHtmlDateStringToMysqlDateTime } from '../../utilities/date';
import {
  testGeocoordinatesFormat,
  getLatitudeRange,
  getLongitudeRange
} from '../../utilities/geolocation';
import {
  USER_TRAVELS_QUERY,
  ALL_LOCATIONS_QUERY,
  LOCATIONS_NEARBY
} from '../../gql/queries';
import {
  CREATE_WAYPOINT,
  CREATE_LOCATION,
  UPLOAD_MEDIA_OBJECT
} from '../../gql/mutations';
import { useDispatch, useSelector } from 'react-redux';
import { push } from 'connected-react-router';
import { locationTypes } from '../../constants/location';
import { locationImageResizeOptions } from '../../constants/miscellaneous';
import { resizeImage } from '../../utilities/image';
import { useLazyQueryPromise } from '../../hooks/useLazyQueryPromise';

// Prepare state mapping
const mapState = state => ({
  userId: state.user.id
});

// Component
const WaypointCreateContainer = ({ match, loader }) => {
  // Get user id from redux store
  const { userId } = useSelector(mapState);

  // Local state definition
  const [formValues, setFormValues] = useState({
    arrivalDate: undefined,
    title: undefined,
    type: Object.keys(locationTypes)[0],
    services: undefined,
    description: undefined,
    imageName: undefined,
    imageFile: undefined,
    geocoordinates: undefined,
    rating: undefined,
    prices: undefined
  });
  const [
    areGeocoordinatesFormattedCorrectly,
    setAreGeocoordinatesFormattedCorrectly
  ] = useState(false);

  // Initialize hook to update redux store
  const dispatch = useDispatch();

  // GQL Mutation: Create waypoint
  const [
    createWaypoint,
    { loading: createWaypointLoading, error: createWaypointError }
  ] = useMutation(CREATE_WAYPOINT, {
    // Update cache
    refetchQueries: [
      {
        query: USER_TRAVELS_QUERY,
        variables: {
          id: iriPrefixes.user + userId
        }
      }
    ]
  });

  // GQL Mutation: Create location
  const [
    createLocation,
    { loading: createLocationLoading, error: createLocationError }
  ] = useMutation(CREATE_LOCATION, {
    // Update cache
    refetchQueries: [{ query: ALL_LOCATIONS_QUERY }]
  });

  // GQL Mutation: Create mediaObject
  const [
    uploadMediaObject,
    { loading: uploadMediaObjectLoading, error: uploadMediaObjectError }
  ] = useMutation(UPLOAD_MEDIA_OBJECT);

  // GQL Query:
  // Get existing locations if the form field geocoordinates it set properly
  const [
    getNearbyLocation,
    { loading: getLocationsNearbyLoading, error: getLocationsNearbyError }
  ] = useLazyQueryPromise(LOCATIONS_NEARBY, {
    variables: {
      latitudeRange:
        areGeocoordinatesFormattedCorrectly &&
        getLatitudeRange(50, formValues.geocoordinates.split(',')[0].trim()),
      longitudeRange:
        areGeocoordinatesFormattedCorrectly &&
        getLongitudeRange(
          50,
          formValues.geocoordinates.split(',')[0].trim(),
          formValues.geocoordinates.split(',')[1].trim()
        )
    }
  });

  // If loading, show loading screen
  if (
    getLocationsNearbyLoading ||
    createLocationLoading ||
    createWaypointLoading ||
    uploadMediaObjectLoading
  ) {
    loader.show();
    return null;
  } else {
    loader.hide();
  }

  // If error hide loading screen and show error
  if (
    createLocationError ||
    createWaypointError ||
    getLocationsNearbyError ||
    uploadMediaObjectError
  ) {
    loader.hide();

    if (createLocationError) return `Error ${createLocationError.message}`;
    if (createWaypointError) return `Error ${createWaypointError.message}`;
    if (getLocationsNearbyError)
      return `Error ${getLocationsNearbyError.message}`;
    if (uploadMediaObjectError)
      return `Error ${uploadMediaObjectError.message}`;
  }

  // Update locale state on any changes of the location form
  const handleFormValueInputChange = value => {
    setFormValues(value);

    // Check, if geocoordinates have the correct format and memorize this in local state
    if (testGeocoordinatesFormat(value.geocoordinates)) {
      setAreGeocoordinatesFormattedCorrectly(true);
    } else {
      setAreGeocoordinatesFormattedCorrectly(false);
    }
  };

  const handleMapClick = event => {
    handleFormValueInputChange({
      ...formValues,
      geocoordinates: Object.keys(event.latlng)
        .map(key => event.latlng[key])
        .join(',')
    });
  };

  const handleFormSubmit = async () => {
    let newMediaObject = '';
    let newLocation;
    let resizedFile;
    let locationId;
    let nearByLocation;

    // Check if the location is already existing
    try {
      nearByLocation = await getNearbyLocation();
    } catch (event) {
      console.error(event.message);
    }

    // If the location is already existing, ask user to take over existing locations data
    if (nearByLocation.data && nearByLocation.data.locations.length) {
      if (
        !window.confirm(
          `Der Zwischenstop kann nicht angelegt werden. Der Ort "${nearByLocation.data.locations[0].title}" existiert bereits! Sollen die Daten des bereits hinterlegten Orts stattdessen übernommen werden?`
        )
      ) {
        // Stop submitting
        return;
      } else {
        // Update the locationId to the id of the already existing location
        locationId = nearByLocation.data.locations[0].id;
      }
    }

    // Create mediaObject if image is set
    if (formValues.imageFile) {
      try {
        resizedFile = await resizeImage(
          formValues.imageFile,
          locationImageResizeOptions
        );
      } catch (event) {
        console.error(event.message);
      }

      resizedFile.name = formValues.imageName;

      try {
        newMediaObject = await uploadMediaObject({
          variables: {
            file: resizedFile
          }
        });
      } catch (event) {
        console.error(event.message);
      }
    }

    // Create location, if it's not already existing
    if (!locationId) {
      try {
        newLocation = await createLocation({
          variables: {
            user: iriPrefixes.user + userId,
            title: formValues.title,
            type: formValues.type,
            services: formValues.services,
            description: formValues.description,
            image: newMediaObject
              ? newMediaObject.data.uploadMediaObject.mediaObject.id
              : undefined,
            latitude: formValues.geocoordinates.split(',')[0],
            longitude: formValues.geocoordinates.split(',')[1],
            rating: Number(formValues.rating),
            prices: formValues.prices
          }
        });
      } catch (event) {
        console.error(event.message);
      }
      // Set location id
      locationId = newLocation.data.createLocation.location.id;
    }

    // Create Waypoint
    try {
      await createWaypoint({
        variables: {
          userId: iriPrefixes.user + userId,
          travelId: iriPrefixes.travel + match.params.id,
          locationId: locationId,
          arrivalDate: convertHtmlDateStringToMysqlDateTime(
            formValues.arrivalDate
          )
        }
      });
    } catch (event) {
      console.error(event.message);
    }

    // Go back to travels page
    dispatch(push(`/travels/${match.params.id}`));
  };

  return (
    <LocationEdit
      navigation={{
        title: 'Reise',
        navigateTo: `/travels/${match.params.id}`,
        icon: 'arrow-left'
      }}
      buttonText="Zwischenstop anlegen"
      isWaypoint={true}
      formValues={formValues}
      handleFormSubmit={handleFormSubmit}
      handleFormValueInputChange={handleFormValueInputChange}
      onClickOfMapFcn={handleMapClick}
    />
  );
};

export default WaypointCreateContainer;
