// Vendor imports
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

// Custom imports
import Login from '../presentationals/templates/Login';
import { loginAction } from '../../actions/authActions';
import { useAuthenticationGuard } from '../../hooks/useAuthenticationGuard';

// Prepare state mapping
const mapState = state => ({
  statusMessage: state.user.authErrorMessage
});

// Component
const LoginContainer = ({ loader }) => {
  // Turn of loader
  useEffect(() => loader.hide());

  const { statusMessage } = useSelector(mapState);
  // Initialize hook to update redux store
  const dispatch = useDispatch();

  // Check authentication in order to set correct state
  useAuthenticationGuard();

  // handling submit of login-form
  const handleSubmit = (event, email, password) => {
    event.preventDefault();
    dispatch(loginAction(email, password));
  };

  return <Login statusMessage={statusMessage} handleSubmit={handleSubmit} />;
};

export default LoginContainer;
