// Vendor imports
import React from 'react';
import classNames from 'classnames';

// Custom imports
import Button from '../atoms/Button';
import Icon from '../atoms/Icon';

// Component
const NavigationHeader = ({ navigation, action, additionalClassNames }) => {
  // Setup css classes
  const cssClasses = classNames('navigation-header', additionalClassNames);

  const renderNavigationIcon = () => {
    return (
      navigation.icon && (
        <Icon
          icon={navigation.icon}
          additionalClassNames="navigation-header__navigation-button-icon"
        />
      )
    );
  };

  // Render block navigation button
  const renderNavigation = () => {
    return (
      navigation.title && (
        <Button
          to={navigation.navigateTo}
          onClickFcn={navigation.onClickFcn}
          styleType="no-styles"
          additionalClassNames="navigation-header__navigation-button"
        >
          {renderNavigationIcon()}
          {navigation.title}
        </Button>
      )
    );
  };

  // Render block action button
  const renderAction = () => {
    const render = action.navigateTo || action.onClickFcn || action.icon;

    return (
      render && (
        <Button
          to={action.navigateTo}
          onClickFcn={action.onClickFcn}
          styleType="no-styles"
          additionalClassNames="navigation-header__action-button"
        >
          <Icon
            icon={action.icon}
            additionalClassNames="navigation-header__action-button-icon"
            circle={true}
          />
          {action.title}
        </Button>
      )
    );
  };

  // Conditional rendering of navigation and action button
  return (
    <header className={cssClasses}>
      {renderNavigation()}
      {renderAction()}
    </header>
  );
};

NavigationHeader.defaultProps = {
  navigation: {
    title: '',
    navigateTo: '',
    onClickFcn: undefined
  },
  action: {
    title: '',
    icon: '',
    navigateTo: '',
    onClickFcn: undefined
  }
};

export default NavigationHeader;
