/**
 * Build a relative url to an api resource
 *
 * @param {string} pathPrefix (optional)
 * @param {string} id
 * @param {string} pathSuffix (optional)
 * @return {string} relative url
 *
 */
export const buildIdUrl = (pathPrefix, id, pathSuffix) => {
  pathPrefix = pathPrefix ? pathPrefix : '';
  pathSuffix = pathSuffix ? pathSuffix : '';

  return `${pathPrefix + removeIriPrefixFromId(id) + pathSuffix}`;
};

/**
 * Remove an iri prefix from an api ressource to get a uid only
 *
 * @param {string} iri - i.e. /api/location/1234-4321-1234-98765
 * @return {string} uid
 *
 */
export const removeIriPrefixFromId = iri => {
  return iri.replace(/^\/api\/[a-z]+\/?/g, '');
};

/**
 * Transform a given text input to be used as slug
 *
 * @param {string} text - Input text to transform
 * @param {string} separator - character used as separater
 * @return {string} slugged text
 *
 */

export const slugify = (text, separator) => {
  text = text
    .toString()
    .toLowerCase()
    .trim();

  const sets = [
    { to: 'a', from: '[ÀÁÂÃÅÆĀĂĄẠẢẤẦẨẪẬẮẰẲẴẶ]' },
    { to: 'ae', from: '[Ä]' },
    { to: 'c', from: '[ÇĆĈČ]' },
    { to: 'd', from: '[ÐĎĐÞ]' },
    { to: 'e', from: '[ÈÉÊËĒĔĖĘĚẸẺẼẾỀỂỄỆ]' },
    { to: 'g', from: '[ĜĞĢǴ]' },
    { to: 'h', from: '[ĤḦ]' },
    { to: 'i', from: '[ÌÍÎÏĨĪĮİỈỊ]' },
    { to: 'j', from: '[Ĵ]' },
    { to: 'ij', from: '[Ĳ]' },
    { to: 'k', from: '[Ķ]' },
    { to: 'l', from: '[ĹĻĽŁ]' },
    { to: 'm', from: '[Ḿ]' },
    { to: 'n', from: '[ÑŃŅŇ]' },
    { to: 'o', from: '[ÒÓÔÕŌŎŐỌỎỐỒỔỖỘỚỜỞỠỢǪǬƠ]' },
    { to: 'oe', from: '[ŒÖØ]' },
    { to: 'p', from: '[ṕ]' },
    { to: 'r', from: '[ŔŖŘ]' },
    { to: 's', from: '[ßŚŜŞŠ]' },
    { to: 'ss', from: '[ß]' },
    { to: 't', from: '[ŢŤ]' },
    { to: 'u', from: '[ÙÚÛŪŨŪŬŮŰŲỤỦỨỪỬỮỰƯ]' },
    { to: 'ue', from: '[Ü]' },
    { to: 'w', from: '[ẂŴẀẄ]' },
    { to: 'x', from: '[ẍ]' },
    { to: 'y', from: '[ÝŶŸỲỴỶỸ]' },
    { to: 'z', from: '[ŹŻŽ]' },
    { to: '-', from: "[·/_,:;']" }
  ];

  sets.forEach(set => {
    text = text.replace(new RegExp(set.from, 'gi'), set.to);
  });

  text = text
    .toString()
    .toLowerCase()
    .replace(/\s+/g, '-') // Replace spaces with -
    .replace(/&/g, '-und-') // Replace & with 'and'
    .replace(/[^\w-]+/g, '') // Remove all non-word chars
    .replace(/--+/g, '-') // Replace multiple - with single -
    .replace(/^-+/, '') // Trim - from start of text
    .replace(/-+$/, ''); // Trim - from end of text

  if (typeof separator !== 'undefined' && separator !== '-') {
    text = text.replace(/-/g, separator);
  }

  return text;
};

/**
 * Check if its an android or ios device
 *
 * @return {string} os-name (ios || android)
 *
 */

export const getMobileOs = () => {
  if (
    navigator.userAgent.indexOf('iPhone') !== -1 ||
    navigator.userAgent.indexOf('iPad') !== -1
  ) {
    return 'ios';
  }
  if (navigator.userAgent.toLowerCase().indexOf('android') > -1) {
    return 'android';
  }

  return undefined;
};
