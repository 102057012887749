// Vendor imports
import React from 'react';
import { useQuery } from '@apollo/react-hooks';

// Custom imports
import Travels from '../presentationals/templates/Travels';
import { useSelector } from 'react-redux';
import { USER_TRAVELS_QUERY } from '../../gql/queries';
import { iriPrefixes } from '../../constants/gql';
import {
  buildIdUrl,
  removeIriPrefixFromId
} from '../../utilities/miscellaneous';
import { getTravelDate } from '../../utilities/date';

// Prepare state mapping
const mapState = state => ({
  userId: state.user.id
});

// Component
const TravelsContainer = ({ loader }) => {
  // Get user id from redux store
  const { userId } = useSelector(mapState);

  // GQL Query: (user travels (User!))
  const { loading, error, data } = useQuery(USER_TRAVELS_QUERY, {
    variables: { id: iriPrefixes.user + userId }
  });

  // If loading, show loading screen
  if (loading) {
    loader.show();
    return null;
  } else {
    loader.hide();
  }

  // If error hide loading screen and show error
  if (error) {
    loader.hide();
    return `Error! ${error.message}`;
  }

  // Sort listed travels by first waypoint arrivalDate
  const sortTravels = travels => {
    // Use a fallback ArrivalDate in case of travels that have no waypoints yet
    // since we need waypoints here to sort travels by date
    const fallbackArrivalDate = '3000-01-01 00:00:00';

    return travels.sort(function(travelA, travelB) {
      return (
        new Date(
          travelA.waypoints[0]
            ? travelA.waypoints[0].arrivalDate
            : fallbackArrivalDate
        ) -
        new Date(
          travelB.waypoints[0]
            ? travelB.waypoints[0].arrivalDate
            : fallbackArrivalDate
        )
      );
    });
  };

  // Get travel start/end date of a travel
  const getTravelDates = waypoints => {
    const startDate = getTravelDate(waypoints);
    const endDate = getTravelDate(waypoints, true);
    return `${startDate} - ${endDate}`;
  };

  // Prepare travel data for rendering in listItem component
  const getTravelListItems = () => {
    const travelsListItems = [];
    const sortedTravels = sortTravels(data.user.travels, true);

    sortedTravels.forEach(travel => {
      travelsListItems.push({
        id: removeIriPrefixFromId(travel.id),
        title: travel.title,
        subtitle: travel.waypoints.length
          ? getTravelDates(travel.waypoints)
          : 'NEU',
        url: buildIdUrl('/travels/', travel.id)
      });
    });

    return travelsListItems;
  };

  // Prepare travel data for rendering in map component
  const getTravelsMapItems = () => {
    const travelsMapItems = [];
    const travels = data.user.travels;

    travels.forEach(travel => {
      travel.waypoints.forEach(waypoint => {
        travelsMapItems.push({
          id: removeIriPrefixFromId(waypoint.id),
          title: waypoint.location.title,
          type: waypoint.location.type,
          services: waypoint.location.services,
          latitude: waypoint.location.latitude,
          longitude: waypoint.location.longitude,
          prices: waypoint.location.prices,
          url:
            buildIdUrl('/travels/', travel.id) +
            buildIdUrl('/waypoints/', waypoint.id)
        });
      });
    });

    return travelsMapItems;
  };

  // Render
  return (
    <Travels
      navigation={{
        title: 'Orte',
        navigateTo: '/',
        icon: 'arrow-left'
      }}
      action={{
        icon: 'plus',
        navigateTo: `/create-travel`
      }}
      itemList={{
        items: getTravelListItems()
      }}
      mapData={{
        items: getTravelsMapItems()
      }}
    />
  );
};

export default TravelsContainer;
