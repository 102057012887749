export const regexPatterns = {
  geocoordinates:
    '^[-+]?([1-8]?\\d(\\.\\d+)?|90(\\.0+)?),\\s*[-+]?(180(\\.0+)?|((1[0-7]\\d)|([1-9]?\\d))(\\.\\d+)?)$'
};

export const locationImageResizeOptions = {
  maxWidth: 1400,
  maxHeight: 787,
  quality: 0.92,
  mimeType: 'image/jpeg',
  returnDataUrl: false,
  crop: true
};
