// Custom imports
import { useAuthenticationGuard } from '../../hooks/useAuthenticationGuard';

// Component
// Prevent to render children, if authentication is not valid
const AuthenticationGuard = ({ children }) => {
  return useAuthenticationGuard() && children;
};

export default AuthenticationGuard;
