// Vendor imports
import React from 'react';
import classNames from 'classnames';
import iconSprite from '../../../images/icon-sprite.svg';

// Component
const Icon = ({ icon, additionalClassNames, circle, bigCircle }) => {
  // Setup css classes
  const cssClasses = classNames(
    'icon',
    {
      [`icon--${icon}`]: true,
      'icon--circle': circle,
      'icon--big-circle': bigCircle
    },
    additionalClassNames
  );
  classNames();
  return (
    <div className={cssClasses}>
      <svg className="icon__svg">
        <use href={`${iconSprite}#icon--${icon}`} />
      </svg>
    </div>
  );
};

export default Icon;
