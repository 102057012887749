// Vendor imports
import React from 'react';
import classNames from 'classnames';

// Component
const Tag = ({ children, additionalClassNames, isActive, disabled }) => {
  // Setup css classes
  const cssClasses = classNames(
    'tag',
    {
      'tag--active': isActive,
      'tag--disabled': disabled
    },
    additionalClassNames
  );

  return <span className={cssClasses}>{children}</span>;
};

export default Tag;
