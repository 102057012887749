// Vendor imports
import React from 'react';
import classNames from 'classnames';

// Custom imports
import Tag from '../atoms/Tag';

// Component
const TagList = ({ tags, additionalClassNames }) => {
  // Setup css classes
  const cssClasses = classNames('tag-list', additionalClassNames);

  return (
    <ul className={cssClasses}>
      {tags &&
        tags.map((tag, index) => {
          return (
            <li className="tag-list__list-item" key={index}>
              <Tag additionalClassNames="tag-list__tag">{tag}</Tag>
            </li>
          );
        })}
    </ul>
  );
};

export default TagList;
