// Vendor imports
import React from 'react';
import classNames from 'classnames';

// Component
const Label = ({ text, htmlFor, additionalClassNames }) => {
  // Setup css classes
  const cssClasses = classNames('label', additionalClassNames);

  return (
    <label htmlFor={htmlFor} className={cssClasses}>
      {text}
    </label>
  );
};

export default Label;
